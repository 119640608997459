<template>
  
</template>

<script>
import { EventBus } from '../../../EventBus.js';
import api from '../../../assets/api.js';
import { mapGetters } from 'vuex';
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import axios from 'axios';
import https from 'https';


export default {
  name: 'BaseACH',
  mixins: [notificationMixin],
  data() {
    return {
      form: { 
        id: '',
        property_id: '', 
        value: {
          production: false
        }, 
        Devices: [],
      },
    
      isToShowEditBtn: false,
      isEditing : false,
      testDeviceLoading: false,
    }
  },
  
  watch: {
    selected () {
        this.setUp();
    }
  },
  props: {
    selected: {
      type: Object
    },
    isEditAble:{
      type: Boolean,
      default: true
    },
    type: {
      type: String
    },
    property_id: {
      type: String
    },
  },
  created () {
     
    this.setUp();
  },
  computed:{
    ...mapGetters({
        hasPermission: 'authenticationStore/rolePermission'
    }), 
   
  },
  components:{
    Loader
  },
  methods: {
     
    setUp(){
       
      if(this.selected){
        this.form = Object.assign(this.form, JSON.parse(JSON.stringify(this.selected)));
        this.isEditing = false;
      } 
      if(!this.selected.id){
        this.form.value = JSON.parse(JSON.stringify(this.defaultValues));
      }
      

    },
    edit(){
      this.isEditing = true;
    }, 
    remove(){
      this.$emit('remove', this.selected);
    }, 
    async save(){
      let status = await this.$validator.validate();

      if(!status){
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.',list: this.errors.items });
        return;
      }
      this.$emit('save', this.form);
    },
    cancel(){
        this.$emit('cancel')
        // this.form = JSON.parse(JSON.stringify(this.selected));
        this.isEditing = false;
    },
    removeDevice(num){
      this.form.Devices.splice(num, 1)
    },
    addDevice(){
      if(!this.form.Devices){
        this.$set(this.form, 'Devices', [])
      }
      this.form.Devices.push({
          id: '',
          name: '',
          ip: '',
          port: '',
          identifier: '',
          hostname: ''
      })
    }, 
    async testDevice(i){
      try {
        this.testDeviceLoading = i;
		if (this.form.Devices[i].lan == 1) {
			// Create an instance of the HTTPS agent with certificate verification enabled.
            const certData = `-----BEGIN CERTIFICATE-----
MIIDzTCCArWgAwIBAgIBAjANBgkqhkiG9w0BAQsFADBtMQswCQYDVQQGEwJVUzEL
MAkGA1UECAwCRkwxDDAKBgNVBAcMA0pBWDEMMAoGA1UECgwDUEFYMRQwEgYDVQQL
DAtJTlRFR1JBVElPTjEfMB0GA1UEAwwWUEFYVVMgVEVSTUlOQUwgUk9PVCBDQTAe
Fw0xODA4MDYwNzMyMTJaFw0yODA4MDMwNzMyMTJaMGoxCzAJBgNVBAYTAlVTMQsw
CQYDVQQIDAJGTDEMMAoGA1UECgwDUEFYMRQwEgYDVQQLDAtJTlRFR1JBVElPTjEq
MCgGA1UEAwwhUEFYVVMgVEVSTUlOQUwgU0VSVkVSIENFUlRJRklDQVRFMIIBIjAN
BgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwbJg+g0N/M+ohU4sTpNHY25j0tH3
6f6kk5M564g6/kBv8WWL/4QnpLHfGVFdBC+P30JW9QjOGJj5OY5suQm0xqs6/rD1
qb1cWbBaeTvOMmx+79uIuQweRAfbAOdc3RdKSz3nR/IfZ1059FG23/8IF9/pRXuM
oeFl4MzWwoxkqJ24Q216aD+fJvGNjoajuBbnyM+5WQDRqBE+GI04ElWDDLLft/Kx
cQwL/gjxFeGSmDg88yHPcimcIulnGnmc6M3SV5RbGVHbCtOwzwjQdUndom9lDZ82
VkBPUaYgiMnFzbbYF9FHFP2xD93qvWrBpc5GGQleDsH8LLHsXbZdPbvdnwIDAQAB
o3sweTAJBgNVHRMEAjAAMAsGA1UdDwQEAwIFoDAdBgNVHQ4EFgQUuWH25D/quAew
5neUSAjaqxe6LCUwHwYDVR0jBBgwFoAUKyQA82I7zBIeEmhqHya07aPrLHYwHwYD
VR0RBBgwFoIIKi5wYXgudXOHBH8AAAGHBMCoYnIwDQYJKoZIhvcNAQELBQADggEB
ADBQHlhlEHzmyVLiAoInaxbP7s8hN29VcWXUQXo5VrbfWCB26zfY0mFgZezMa7VB
dzzflJ/XeqDs+HgQ5KQ5cXL4PO+jpFvPLPj7wfy02YdyjuKu/EDMkrxLXwJm8TJY
qeVd/W5phCxqXc/+5jvvrGGdOTd+tgpz/DndKl4mdboqWeqCJxVWbxZez2FDrUzP
cPHhEt+gfJFr2ARYhPwf7jD5/97YV2ULeYxoOkr+qF5GdTdSxn944FM8l8ZGrRpe
rkhYcw+B0V6MgdoFGhkBDXbos0KiUUbLVgCyf+ewUQuc1+2q9++s+amJ0svydr1V
0gVZN3O9nql8n0IcpWtsyvQ=
-----END CERTIFICATE-----`;

			const httpsAgent = new https.Agent({
			  ca: [certData],
			});

			const instance = axios.create({
			  httpsAgent
			});

			// Make the GET request using the custom Axios instance.
			const response = await instance.get(`https://device${i + 1}.pax.us:${this.form.Devices[i].port}?AkEwMBwxLjI4A0s=`, {headers: {'Accept': 'text/plain',},});
			console.log("response:", response.data);
			console.log("response:");
			console.log(response);
			this.deviceData = response.data;
            this.showMessageNotification({ type: 'success', description: 'Connected to the credit card reader successfully.' });
		} else {
          let r = await api.post(this, api.PROPERTIES + `${this.property_id}/connections/${this.form.id}/devices/${this.form.Devices[i].id}/validate`);
          this.showMessageNotification({ type: 'success', description: 'Connected to the credit card reader successfully.' });
		}
      } catch(err){
        this.showMessageNotification({ description: err.toString() });
      }
      this.testDeviceLoading = null;
      
    }
  },
}
</script>