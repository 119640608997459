<template>
  <div class="rm-content">
    <v-row
      v-if="level == 'Property'"
      no-gutters
      class="mt-n2"
    >
      <HbPropertySelector
        v-show="!isOPS"
        id="facility"
        :items="properties"
        item-text="name"
        item-value="id"
        v-validate="'required'"
        v-model.lazy="property_id"
        data-vv-scope="gate"
        data-vv-name="facility"
        data-vv-as="Property"
        :error="errors.has('gate.facility')"
      />
    </v-row>
    <div v-if="property_id || !isPropertyLevel">
      <div class="pa-0 mr-0 my-4">
        <v-row class="w-100 mx-0">
          <v-col cols="11" class="ma-0 pa-0">
              <hb-autocomplete
                  rounded
                  :items="menuOptions"
                  item-text="label"
                  item-value="key"
                  placeholder="Select Space Type"
                  @change="setMenuOption"
                  v-model="activeTab"
                  width="370px"
                  return-object
                  style="display:inline-block"
                  :clearable="false"
              >
              </hb-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div class="primary-section-content">
        <div class="mr-6">
          <v-expansion-panels
            elevation="0"
            class="hb-expansion-panel"
            v-model="panel"
            :key="level"
            multiple
          >
            <coverage-options
              :activeTab="activeTab"
              :coverage="coverageData"
              :level="currentLevel"
              @handleCancelCoverage="handleCancelCoverage"
              @saveCoverageOptions="saveCoverageOptions"
              @handleCoverageToggle="handleCoverageToggle"
              @handleUseCoverageCorporateDefaults="handleUseCoverageCorporateDefaults"
              @handleUncheckCoverageCorporateDefults="getDefaultCoverageDetails"
              @setDefaultCoverage="setDefaultCoverage"
            />
            <free-insurance
              v-if="!!+companySettings[SETTING_ENUMS.ALLOW_FREE_INSURANCE]"
              :activeTab="activeTab"
              :insurance="insuranceData"
              :level="currentLevel"
              :coverageType="coverageType"
              :savedInsuranceTemplates="coverageOptions"
              @handleCancelInsurance="handleCancelInsurance"
              @saveFreeInsurance="saveFreeInsurance"              
              @handleFreeCoverageToggle="handleFreeCoverageToggle"
              @handleUseCorporateDefaults="handleUseCorporateDefaults"
              @handleUncheckCorporateDefults="getDefaultFreeInsuranceDetails" 
              @setDefaultFreeInsurance="setDefaultFreeInsurance"
            />
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import MenuTabs from "./../Common/MenuTabs.vue";
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import FreeInsurance from "./FreeInsurance.vue";
import CoverageOptions from "./CoverageOptions.vue";
import INSURANCE from "@/constants/insurance.js";
import SETTING_ENUMS from '@/constants/setting.js';

export default {
  name: "InsuranceSettingsTab",
  mixins: [notificationMixin],
  props: {
    level: {
      type: String,
      default: "",
    },
    facilityID: {
      type: String,
      default: "",
    },
    isOPS: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOptions: [],
      showSidebar: true,
      activeTab: "property",
      unitType: {},
      confirmUncheckCorporate: false,
      confirmUncheckCoverageCorporate: false,
      coverageType: INSURANCE.Library,
      insuranceData: {
        is_enabled: false,
        useCorporateDefaults: false,
        premiumThresholdType: "premium",
        premiumThreshold: "",
        invoiceDaysThreshold: "",
        selectedSpaceTypes: [this.activeTab],
      },
      freeInsurances: {},
      coverageOptions: {},
      coverageData: {
        is_enabled: false,
        useCorporateDefaults: false,
        coverageType: INSURANCE.Library,
        coverageName: "",
        coverageDescription: "",
        tableRateTiers: [{ premium_rate: 0, coverage_limit: 0 }],
        isTaxable: "",
        isProrateIn: "",
        isProrateOut: "",
        incomeAccount: "",
        selectedSpaceTypes: [],
      },
      property_id: null,
      accounts: [],
      panel: [],
    };
  },
  components: {
    MenuTabs,
    FreeInsurance,
    CoverageOptions,
  },
  async created() {
    this.INSURANCE = INSURANCE;
    this.SETTING_ENUMS = SETTING_ENUMS;
    this.menuOptions = this.unitTypes.map((item) => ({
      key: item.id,
      label: item.name,
    }));
    this.activeTab = this.menuOptions[0].key;
    this.insuranceData && Array.isArray(this.insuranceData.selectedSpaceTypes) && this.insuranceData.selectedSpaceTypes.push(this.menuOptions[0].key);
    this.coverageData && Array.isArray(this.coverageData.selectedSpaceTypes) && this.coverageData.selectedSpaceTypes.push(this.menuOptions[0].key);
    await this.fetchAccounting();
    this.fetchFreeInsuranceData();
    this.fetchCoverageData();
  },
  computed: {
    ...mapGetters({
      defaultProperty: "propertiesStore/defaultProperty",
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
      unitTypes: "filterStore/unit_type",
      companySettings: 'authenticationStore/getSettings',
    }),
    currentLevel() {
      return this.level.toLowerCase();
    },
    isPropertyLevel() {
      return this.level === "Property";
    },
  },

  watch: {
    property_id() {
      if (this.property_id) {
        this.setDefaultProperty(
          this.properties.find((e) => e.id == this.property_id)
        );
        this.fetchFreeInsuranceData();
        this.fetchCoverageData();
      }
    },
    level() {
      if (this.level == "Property") {
        this.property_id = this.defaultProperty
          ? this.defaultProperty.id
          : null;
      }
      if (!this.property_id) this.activeTab = this.menuOptions[0].key;
      this.fetchFreeInsuranceData();
      this.fetchCoverageData();
    },
  },

  methods: {
    ...mapActions({
      setDefaultProperty: "propertiesStore/setDefaultProperty",
    }),
    async fetchAccounting() {
      let p = await api.get(this, api.ACCOUNTING + "accounts");
      this.accounts = p.accounts
        ? p.accounts.filter(
            (a) =>
              a.category &&
              ["income", "expense", "liability"].includes(
                a.category.toLowerCase()
              )
          )
        : [];
    },
    async fetchFreeInsuranceData() {
      try {
        let path =
          this.level === "Property"
            ? `${api.FREE_COVERAGE_PROPERTY}${this.property_id}/free-insurance`
            : api.FREE_COVERAGE;
        let data = await api.get(this, path);
        this.freeInsurances = data.FreeInsurances;
        this.menuOptions && this.menuOptions.forEach(menuOption => {
          this.freeInsurances[menuOption.key] = this.freeInsurances[menuOption.key] || {
              unit_type_id: menuOption.key,
              FreeInsuranceTemplate: {
                is_enabled: false,
                type: INSURANCE.FREE_INSURANCE_TYPES.PREMIUM.KEY,
                UnitTypes: [{ id: menuOption.key }],
                is_found: false,
            }
          }
        });
        this.activeTab = this.activeTab
          ? this.activeTab
          : this.menuOptions[0].key;
        const selectedFreeInsuranceData =
          this.freeInsurances[this.activeTab];
        this.insuranceData = this.mapInsuranceData(selectedFreeInsuranceData);
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async fetchCoverageData() {
      try {
        let path =
          this.level === "Property"
            ? api.FREE_COVERAGE_PROPERTY +
              this.property_id +
              "/insurance-templates"
            : api.COVERAGE_OPTIONS;
        let data = await api.get(this, path);
        this.coverageOptions = data.InsuranceTemplates;
        this.menuOptions && this.menuOptions.forEach(menuOption => {
          if (!menuOption.key) return
          this.coverageOptions[menuOption.key] = this.coverageOptions[menuOption.key] || {
              unit_type_id: menuOption.key,
              InsuranceTemplate: {
                is_enabled: true,
                type: INSURANCE.Library,
                UnitTypes: [{ id: menuOption.key }],
                is_found: false,
            }
          }
        });
        this.activeTab = this.activeTab
          ? this.activeTab
          : this.menuOptions[0].key;
        const selectedCoverageData =
          this.coverageOptions && this.coverageOptions[this.activeTab];
        this.coverageData = selectedCoverageData
          ? this.mapCoverageOptionValues(selectedCoverageData)
          : this.getDefaultCoverageDetails();
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async saveFreeInsurance(data, payload = {}) {
      try {
        const {
          premiumThresholdType,
          premiumThreshold,
          invoiceDaysThreshold,
          is_enabled,
          selectedSpaceTypes,
        } = data;
        const { should_fetch = true} = payload;
        const freeInsurances = {
          unit_type_id: this.activeTab,
          FreeInsuranceTemplate: {
            amount:
              premiumThresholdType === "coverage"
                ? Number(premiumThreshold) * INSURANCE.THOUSAND
                : premiumThreshold,
            cancellation_threshold: invoiceDaysThreshold,
            is_enabled: is_enabled ? 1 : 0,
            type: premiumThresholdType,
            UnitTypes: selectedSpaceTypes.map((id) => ({ id })),
          },
        };
        const path =
          this.level === "Property"
            ? `${api.FREE_COVERAGE_PROPERTY}${this.property_id}/free-insurance`
            : `${api.FREE_COVERAGE}`;
        await api.put(this, path, freeInsurances);
        this.showMessageNotification({
          type: "success",
          description: "You updated Free Coverage Period.",
        });
        should_fetch && this.fetchFreeInsuranceData();
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async saveCoverageOptions(data, payload = {}) {
      try {
        const {
          coverageType,
          is_enabled,
          selectedSpaceTypes,
          coverageName,
          coverageDescription,
          isTaxable,
          isProrateIn,
          isProrateOut,
          tableRateTiers,
          incomeAccount,
        } = data;
        const { should_fetch = true} = payload;
        const coverageOptions = {
          unit_type_id: this.activeTab,
          InsuranceTemplate: {
            type: coverageType,
            is_enabled: is_enabled ? 1 : 0,
            UnitTypes: selectedSpaceTypes.map((id) => ({ id })),
            Insurance: {
              name: coverageName,
              description: coverageDescription,
              taxable: isTaxable || 0,
              prorate: isProrateIn || 0,
              prorate_out: isProrateOut || 0,
              TableRateTiers: tableRateTiers
                ? tableRateTiers.filter((x) => Number(x.premium_rate) > 0)
                : [],
              income_account_id: incomeAccount,
            },
          },
        };
        const path =
          this.level === "Property"
            ? api.FREE_COVERAGE_PROPERTY + this.property_id + "/insurance-templates"
            : api.COVERAGE_OPTIONS;
        await api.put(this, path, coverageOptions);
        this.showMessageNotification({
          type: "success",
          description: "You updated Coverage Option Types.",
        });
        should_fetch && this.fetchCoverageData();
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async setDefaultFreeInsurance() {
      try {
        const path = `${
          this.level === "Property"
            ? `${api.FREE_COVERAGE_PROPERTY}${this.property_id}/free-insurance`
            : api.FREE_COVERAGE
        }/unit_types/${this.activeTab}`;
        await api.delete(this, path);
        this.showMessageNotification({
          type: "success",
          description: "You have set use corporate default for the property.",
        });
        this.confirmUncheckCorporate = false;
        await this.fetchFreeInsuranceData();
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async setDefaultCoverage() {
      try {
        const path =
          (this.level === "Property"
            ? api.FREE_COVERAGE_PROPERTY +
              this.property_id +
              "/insurance-templates"
            : api.FREE_COVERAGE) +
          "/unit_types/" +
          this.activeTab;
        await api.delete(this, path);
        this.showMessageNotification({
          type: "success",
          description: "You have set use corporate default for the property.",
        });
        this.confirmUncheckCoverageCorporate = false;
        await this.fetchCoverageData();
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    mapCoverageOptionValues(data) {
      try {
        const InsuranceTemplate =
          data && data.InsuranceTemplate ? data.InsuranceTemplate : null;
        const is_enabled = InsuranceTemplate
          ? InsuranceTemplate.is_enabled == 1
            ? true
            : false
          : false;
        const useCorporateDefaults = data
          ? data.property_id
            ? false
            : true
          : true;
        let selectedSpaceTypes = InsuranceTemplate && InsuranceTemplate.UnitTypes
          ? InsuranceTemplate.UnitTypes.map((x) => x.id)
          : [];
        selectedSpaceTypes = selectedSpaceTypes.includes(this.activeTab)
          ? selectedSpaceTypes
          : [...selectedSpaceTypes, this.activeTab];
        const coverageType = InsuranceTemplate
          ? InsuranceTemplate.type
          : INSURANCE.Library;
        this.coverageType = coverageType;
        const Insurance =
          InsuranceTemplate && InsuranceTemplate.Insurance
            ? InsuranceTemplate.Insurance
            : null;
        const coverageName = Insurance ? Insurance.name : "";
        const coverageDescription = Insurance ? Insurance.description : "";
        const isTaxable = Insurance ? Insurance.taxable : "0";
        const isProrateIn = Insurance ? Insurance.prorate : "0";
        const isProrateOut = Insurance ? Insurance.prorate_out : "0";
        const incomeAccount = Insurance ? Insurance.income_account_id : "";
        const tableRateTiers = Insurance ? Insurance.TableRateTiers : [];
        const is_found =InsuranceTemplate?.is_found !== false;
        return {
          is_enabled,
          useCorporateDefaults,
          selectedSpaceTypes,
          coverageType,
          coverageName,
          coverageDescription,
          isTaxable,
          isProrateIn,
          isProrateOut,
          incomeAccount,
          tableRateTiers,
          is_found,
        };
      } catch (error) {
        throw error;
      }
    },
    mapInsuranceData(data) {
      try {
        const freeInsuranceTemplate = data?.FreeInsuranceTemplate;
        const premiumThresholdType = freeInsuranceTemplate ? freeInsuranceTemplate.type : "premium";
        const premiumThreshold = freeInsuranceTemplate
          ? premiumThresholdType === "coverage"
            ? Number(freeInsuranceTemplate.amount) / INSURANCE.THOUSAND
            : freeInsuranceTemplate.amount
          : 0;
        let selectedSpaceTypes = freeInsuranceTemplate && freeInsuranceTemplate.UnitTypes ? freeInsuranceTemplate.UnitTypes.map((x) => x.id) : [];
        selectedSpaceTypes = selectedSpaceTypes.includes(this.activeTab)
          ? selectedSpaceTypes
          : [...selectedSpaceTypes, this.activeTab];
        const invoiceDaysThreshold = freeInsuranceTemplate ? freeInsuranceTemplate.cancellation_threshold : 0;
        const is_enabled = freeInsuranceTemplate ? (freeInsuranceTemplate.is_enabled == 1 ? true : false) : false;
        const useCorporateDefaults = data
          ? data.property_id
            ? false
            : true
          : true;
        const is_found = freeInsuranceTemplate?.is_found !== false;
        return {
          premiumThreshold,
          selectedSpaceTypes,
          invoiceDaysThreshold,
          is_enabled,
          useCorporateDefaults,
          premiumThresholdType,
          is_found,
        };
      } catch (error) {
        this.showMessageNotification({ description: error });
        throw error;
      }
    },
    async setMenuOption(option) {
      option = option.key;
      this.activeTab = option;
      const defaultSpaceType = this.menuOptions.find(
        (x) => x.key === this.activeTab
      ).key;
      const selectedFreeInsuranceData =
        this.freeInsurances[this.activeTab];
      this.insuranceData = selectedFreeInsuranceData
        ? this.mapInsuranceData(selectedFreeInsuranceData)
        : this.getDefaultFreeInsuranceDetails();
      const selectedCoverageData =
        this.coverageOptions && this.coverageOptions[this.activeTab];
      this.coverageData = selectedCoverageData
        ? this.mapCoverageOptionValues(selectedCoverageData)
        : this.getDefaultCoverageDetails();
      this.coverageData.selectedSpaceTypes =
        selectedCoverageData && selectedCoverageData.InsuranceTemplate
          ? selectedCoverageData.InsuranceTemplate.UnitTypes.map((x) => x.id)
          : [defaultSpaceType];
    },
    async handleCancelInsurance() {
      await this.fetchFreeInsuranceData();
      const selectedFreeInsuranceData =
        this.freeInsurances[this.activeTab];
      this.insuranceData = selectedFreeInsuranceData
        ? this.mapInsuranceData(selectedFreeInsuranceData)
        : this.getDefaultFreeInsuranceDetails();
    },
    async handleCancelCoverage() {
      await this.fetchCoverageData();
      const selectedCoverageData =
        this.coverageOptions && this.coverageOptions[this.activeTab];
      this.coverageData = selectedCoverageData
        ? this.mapCoverageOptionValues(selectedCoverageData)
        : this.getDefaultCoverageDetails();
    },
    handleFreeCoverageToggle(data) {
      const savedFreeInsuranceData = this.mapInsuranceData(this.freeInsurances[this.activeTab]);
      const freeInsuranceDataToSave = this.getDefaultFreeInsuranceDetails(savedFreeInsuranceData);
      freeInsuranceDataToSave.is_enabled = data.is_enabled;
      this.saveFreeInsurance(freeInsuranceDataToSave, { should_fetch : false});
    },
    handleCoverageToggle(data) {
      const savedCoverageData = this.mapCoverageOptionValues(this.coverageOptions[this.activeTab]);
      const coverageDataToSave = this.getDefaultCoverageDetails(savedCoverageData);
      coverageDataToSave.is_enabled = data.is_enabled;
      this.saveCoverageOptions(coverageDataToSave, { should_fetch : false});
    },
    getDefaultFreeInsuranceDetails(data = {}) {
      return { 
        is_enabled: data.is_enabled || false,
        premiumThresholdType: data.premiumThresholdType || "premium",
        premiumThreshold: data.premiumThreshold || 1, 
        invoiceDaysThreshold: data.invoiceDaysThreshold || 20,
        selectedSpaceTypes: data.selectedSpaceTypes || [this.activeTab],
        is_found: true,
      };
    },
    getDefaultCoverageDetails(data = {}) {
      return {
        is_enabled: data.is_enabled || true,
        coverageType: data.coverageType || INSURANCE.Library,
        coverageName: data.coverageName || "",
        coverageDescription: data.coverageDescription || null,
        isTaxable: data.isTaxable || null,
        isProrateIn: data.isProrateIn || null,
        isProrateOut: data.isProrateOut || null,
        incomeAccount: data.incomeAccount || null,
        tableRateTiers: data.tableRateTiers || [],
        selectedSpaceTypes: data.selectedSpaceTypes || [this.activeTab],
        is_found: true,
      };
    },
    handleUseCorporateDefaults(value) {
      if (value) this.confirmUncheckCorporate = true;
    },
    handleUseCoverageCorporateDefaults(value) {
      if (value) this.confirmUncheckCoverageCorporate = true;
    },
  },
};
</script>

<style lang="scss"></style>
