<template>
    <div>
      <v-row v-if="level == 'Property'" no-gutters class="mt-n2">
        <HbPropertySelector
          v-show="!isOPS"
          id="facility"
          :items="facilityList"
          item-text="name"
          item-value="id"
          v-validate="'required'"
          v-model.lazy="property_id"
          data-vv-scope="gate"
          data-vv-name="facility"
          data-vv-as="Property"
          :error="errors.has('gate.facility')"
        />
      </v-row>

    <div class="mr-6" :class="level == 'Property' ? 'mt-n2' : 'mt-4'">

      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

      <hb-notification
        v-model="showIncomeAccountAlert"
        v-if="accountingToggleOn && showIncomeAccountAlert && level === 'Corporate'"
        @close="showIncomeAccountAlert = false"
        type="caution"
        :notDismissable="true"
      >
        There are some coverage(s) with unconfigured gl account.
      </hb-notification>

      <div v-if="level == 'Corporate' || (level == 'Property' && property_id)">
      
        <hb-data-table-header>
          <template v-slot:description>
            Configure your coverage.
          </template>
          <template v-slot:actions>
            <hb-btn v-if="level == 'Corporate'" small color="secondary" @click="addItem()">Add Coverage Template</hb-btn>
          </template>
        </hb-data-table-header>
        
        <v-row class="mt-2 mb-6 px-4" v-show ="isOPS" v-if="level === 'Corporate' || level === 'Property'">
          <v-col cols="9" class="ma-0 pa-0 hb-text-light">
              
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 text-right">
                <hb-btn  v-if="isOPS" color="secondary"   @click="addItem()" >
                    Add Coverage Plan
              </hb-btn>
          </v-col>
        </v-row>

        <hb-data-table
          :headers="tableHeader"
          :items="insurance"
          @click:row="editItem"
        >
          <template v-slot:item.name="{ item }">
            {{item.name}}<br />
            <span class="light-text">{{item.description}}</span>
          </template>
          <template v-slot:item.gl_account_code="{ item }">
            <span v-if="item.gl_account_active == 0" class="pr-1">
              <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
            </span>
            <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
          </template>
          <template v-slot:item.gl_account_name="{ item }">
            <span v-if="item.gl_account_active == 0" class="pr-1">
              <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
            </span>
            <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
          </template>
          <template v-if="level == 'Property'" v-slot:item.recurring="{ item }">
            {{ item.recurring ? 'Yes' : 'No' }}
          </template>
          <template v-else v-slot:item.premium_value="{ item }">
              <span v-show="item.premium_type == '$'">${{item.premium_value}}</span>
              <span v-show="item.premium_type == '%'">{{item.premium_value}}%</span>
          </template>
          <template v-if="level == 'Property'" v-slot:item.prorate="{ item }">
            {{ item.prorate ? 'Yes' : 'No' }}
          </template>
          <template v-else v-slot:item.coverage="{ item }">
              ${{item.coverage}}
          </template>
          <template v-if="level == 'Property'" v-slot:item.prorate_out="{ item }">
            {{ item.prorate_out ? 'Yes' : 'No' }}
          </template>
          <template v-else v-slot:item.deductible="{ item }">
              ${{item.deductible}}
          </template>
          <template v-slot:item.actions="{ item }">
            <hb-menu
              options
              align-right
            >
              <v-list>
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
        </hb-data-table>
      </div>

      <hb-empty-state
        v-else
        message="Please select a Property to continue."
        class="mt-2"
      >
      </hb-empty-state>
    </div>

    <edit-insurance v-model="showAdd" v-if="showAdd" :isOPS="isOPS" :productLevel="level" :facilityID="facilityID || property_id" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData"></edit-insurance>
    <edit-insurance v-model="showEdit" v-if="showEdit" :isOPS="isOPS" :selected="selected" :productLevel="level" :facilityID="facilityID || property_id" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData"></edit-insurance>

    <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete Coverage" @close="closeWindow" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6">
          Are you sure you want to delete the selected coverage?
          <br /><br />
          This action cannot be undone.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="destructive" @click="deleteConfirm">Delete</hb-btn>
      </template>
    </hb-modal>

  </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import EditInsurance from './EditInsurance.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import settingsTabMixin from '../../mixins/settingsTabMixin.js';
    import { mapActions, mapGetters } from 'vuex';
  	import { notificationMixin } from "../../mixins/notificationMixin.js";

    export default {
        name: "InsuranceLibraryTab",
        mixins:[settingsTabMixin, notificationMixin],
        props: {
            level : {
                type: String,
                default: ''
            },
            facilityID: {
                type : String,
                default: ''
            },
            isOPS:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                insurance:[],
                selected: {},
                showAdd: false,
                showEdit: false,
                showDelete: false,
                destroyComponent : true,
                showIncomeAccountAlert: false,
                property_id: ''
            }
        },
        components:{
            Modal,
            EditInsurance,
            Status
        },
        created(){
            if (this.level === 'Corporate' || this.$props.facilityID) {
                this.fetchData();
            } else if (this.isOPS && this.level === 'Property') {
              this.fetchUnitTypes();
            }
            this.getAccountingSettings();
        },
        watch: {
            property_id(p) {
              if(this.property_id && this.property_id != "") {
                this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id));
                this.fetchData();
              }
            },
            level(l) {
              if(this.level === 'Property') {
                this.property_id = this.defaultProperty ? this.defaultProperty.id : "";
              } else {
                this.fetchData();
              }
              
            }
        },
        computed:{
            ...mapGetters({
              facilityList: 'propertiesStore/filtered',
              accountingSettings: "accountingStore/accountingSettings",
              onboardingData: "onboardingTabsStore/getOnboardingData",
              defaultProperty: "propertiesStore/defaultProperty"
            }),
            tableHeader(){
              let thead = [];
              thead.push(
                { text: "Name", value: "name" },
                { text: "GL Code", value: "gl_account_code" },
                { text: "GL Name", value: "gl_account_name" }
              );
              if (this.level === 'Property') {
                thead.push(
                  { text: "Recurring", value: "recurring" },
                  { text: "Prorate In", value: "prorate" },
                  { text: "Prorate Out", value: "prorate_out" }
                );
              } else {
                thead.push(
                  { text: "Premium", value: "premium_value" },
                  { text: "Coverage", value: "coverage" },
                  { text: "Deductible", value: "deductible" }
                );
              }
              thead.push({ text: "", value: "actions", align: "right", sortable: false, width: 10 });
              return thead;
            },
            accountingToggleOn(){
              return Object.keys(this.accountingSettings).length && this.accountingSettings.toggleAccounting == "1"? true : false;
            }
        },
        filters:{
            formatPrice(val){
                if(!val) return '-';
                return "$" + val;
            }
        },
        methods:{
          ...mapActions({
            getAccountingSettings: "accountingStore/getAccountingSettings",
            setDefaultProperty: 'propertiesStore/setDefaultProperty'
          }),
            initializeData(){
              this.insurance = [];
            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
                setTimeout(() => {
                    this.destroyComponent = false;
                }, 200);
            },
            fetchData() {
              this.insurance = [];
              let path = this.facilityID || this.level === 'Property' ? api.PROPERTIES + (this.facilityID || this.property_id) + '/products?type=insurance' : api.INSURANCE
              api.get(this, path).then(r => {
                  if (this.level === 'Property') {
                      this.insurance = r.products;
                  } else {
                      this.insurance = r.insurance;
                  }
                  this.showIncomeAccountAlert = this.insurance.filter(i => !i.income_account_id).length && this.level === 'Corporate'? true : false;
              });
            },
            addItem(){
                this.destroyComponent = true;
                this.showAdd = true;
            },
            editItem(c){
                this.selected = c;
                this.destroyComponent = true;
                this.showEdit = true;
                // this.showAdd = true;
            },
            deleteItem(c){
                this.selected = c;
                this.showDelete = true;
            },
            async deleteConfirm(){
                if(this.level === 'Property') {
                  await api.delete(this, api.PROPERTIES, `${this.facilityID || this.property_id}/products/${this.selected.id}`);
                } else {
                  await api.delete(this, api.INSURANCE, this.selected.id);                  
                }

                this.showDelete = false;
                this.fetchData();
                this.selected = {};
            },
            showSuccessStatus(name, isEdited = false){
                //this.successSet(this.$options.name, 'Insurance Template "' + name + ( isEdited ? '" Updated' : '" Created'));
                let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                  new Date(),
                  "MMM DD, YYYY @ h:mma."
                );
                let message = ( isEdited ? "The '" + name + "' has been updated on " + currentLocalDate : "The '" + name + "' has been created on " + currentLocalDate);
				this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
            }
        },
        mounted(){
           if(this.isOPS && this.level === 'Property' && this.onboardingData.propertyData.length > 0){
             this.facilityID = this.onboardingData.propertyData[0].property_id;
             this.fetchData()   
          }
 
        }

    }
</script>

<style scoped>
  .add-template-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-template-btn:focus{
    border: none;
  }
  .ins-templete-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .ins-templete-table .table-head{
    background: #FFFFFF;
    box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25);
  }
  .ins-templete-table .table-head strong{
    font-weight: 500;
    font-size: 15px;
    color: #474F5A;
  }
  .ins-templete-table .table-row:hover:not(:first-child){
    /* background: #F9FAFB; */
    cursor: inherit;
  }
  .ins-templete-table .table-row:first-child {
    cursor: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.001);
  }
  .ins-templete-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
  }
  .light-text{
    color: #637381;
  }
  .key-heading{
    font-size: 15px;
    color: #000;
  }
</style>

<style>
  .subdued {
    color: #a5adb4;
    line-height: 18px;
  }
</style>

