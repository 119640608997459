<template>
    <div class="navigation-panel-container">
        <hb-blade-header
            title="Add / Edit Stage"
            title-icon-off
            back-button-off
            @close="close"
        >
        </hb-blade-header> 
        <div class="panel-content">
          
            <div class="main-panel-content">
                <p class="hb-font-header-2-medium">Stage Info</p>

                <hb-card class="mt-1 mb-6" noTitle>   
                    <hb-form label="Stage Name" required>
                        <HbTextField
                            v-model="stage.name"
                            v-validate.disable="'required'"
                            id="name"
                            name="name"
                            hide-details
                            :error-messages="errors.collect('stage.name')"
                            :class="{'custom-field-error' : errors.first('stage.name')}"
                            label="Name"
                            data-vv-scope="stage"
                            single-line
                            @blur="checkValidation('stage.name')"
                        ></HbTextField>
                    </hb-form>

                    <hb-form label="Description">
                        <v-text-field
                            v-model="stage.description"
                            id="description"
                            name="description"
                            :hide-details="!errors.collect('description').length"
                            :error-messages="errors.collect('description')"
                            label="Description"
                            single-line
                        ></v-text-field>
                    </hb-form>

                    <hb-form label="When does this stage happen?" required multiple>
                        <span class="grayColor">1. Link this stage to an event.</span>
                        <v-row no-gutters class="pa-0 ma-0 mt-4 mb-6 d-flex align-center">
                            <v-col class="pa-0 mt-0 mb-2 pb-0 mr-2 col-12">
                                <HbSelect style="padding: 8px 10px 0px 10px;" full v-model="stage.trigger_action_dependency_type_id"
                                    :items="stageTriggerActionDependencyTypes" v-validate="'required'"
                                    data-vv-name="event" data-vv-as="event" item-value="id"
                                    item-text="name" id="slug" :error="errors.has('stage.event')"
                                    @blur="checkValidation('stage.event')" :class="{'custom-field-error' : errors.first('stage.event')}"
                                    data-vv-scope="stage"
                                    placeholder="Select Event">
                                    <template v-slot:selection="data">
                                        <span v-html="data.item.name"></span>
                                        <hb-tooltip v-if="data.item.tooltip">
                                            <template v-slot:body>
                                                <span v-html="data.item.tooltip"></span>
                                            </template>
                                        </hb-tooltip>
                                    </template>
                                    <template v-slot:item="data">
                                        <span v-html="data.item.name"></span>
                                        <hb-tooltip v-if="data.item.tooltip">
                                            <template v-slot:body>
                                                <span v-html="data.item.tooltip"></span>
                                            </template>
                                        </hb-tooltip>
                                    </template>
                                </HbSelect>
                            </v-col>
                            <v-col class="pa-0 mt-0 col-12" v-if="stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_ACTION">
                                <HbAutocomplete style="padding: 8px 10px 0px 10px;" search v-model="stage.parent_action_id" :items="allStageEvents"
                                    v-validate="'required'" data-vv-name="events"
                                    data-vv-as="Events" item-value="action_id" item-text="full_action_name"
                                    :error="errors.has('stage.events')" placeholder="Search Event" id="action_id"
                                    @blur="checkValidation('stage.events')" data-vv-scope="stage"
                                    :class="{'custom-field-error' : errors.first('stage.events')}"
                                    >
                                    <template v-slot:selection="data">
                                        <div v-html="data.item.full_action_name"></div>
                                    </template>
                                    <template v-slot:item="data">
                                        <div v-html="data.item.full_action_name"></div>
                                    </template>
                                </HbAutocomplete>
                            </v-col>
                        </v-row>
                        <span class="grayColor">2. Select the timeframe when this stage will take place.</span>
                        <v-row
                            no-gutters
                            class="pa-0 ma-0 mt-4 mb-6 d-flex align-center"
                        >
                            <v-col class="pa-0 mt-0 mb-0 pb-0 mr-2">
                                <HbSelect
                                   style="padding: 8px 10px 0px 10px;"
                                    v-model="stageTimeFrame"
                                    :items="stageTimeFrames"
                                    item-value="slug"
                                    item-text="content"
                                    id="id"
                                    v-validate="'required'"
                                    data-vv-name="timeframe"
                                    data-vv-as="timeframe"
                                    :error="errors.has('stage.timeframe')"
                                    placeholder="Timeframe"
                                    @blur="checkValidation('stage.timeframe')"
                                    :class="{'custom-field-error' : errors.first('stage.timeframe')}"
                                    data-vv-scope="stage"
                                > 
                                </HbSelect>
                            </v-col>
                            <v-col class="pa-0 mt-0" v-if="stageTimeFrame === AUTOMATION.DAYS">
                                <HbTextField
                                    style="width:60px;display:inline-block;border-radius: 4px;border:1px solid #ddd;padding: 8px 10px 0px 10px;"
                                    v-model="stage.start" 
                                    v-validate.disable="'required|min_value:1'" 
                                    id="start"
                                    name="start"
                                    hide-details
                                    :error-messages="errors.collect('stage.start')" 
                                    label="Count of days" 
                                    single-line
                                    @blur="checkValidation('stage.start')"
                                     :class="{'custom-field-error' : errors.first('stage.start')}"
                                    data-vv-scope="stage"
                                />
                            </v-col>
                        </v-row>
                    </hb-form>

                    <hb-form 
                    label="Payment Reversal"
                    :description="AUTOMATION.PAYMENT_REVERSAL_DESCRIPTION"
                    >
                        <hb-checkbox
                            v-model="stage.is_anchor_stage"
                            label="Mark this stage as the payment reversal stage"
                            @change="handleAnchorStageChange"
                            :disabled="[AUTOMATION.AFTER_ACTION, AUTOMATION.AFTER_PTOD].includes(stage.trigger_action_dependency_type_slug) ? true : false"
                        >
                        <template v-if="[AUTOMATION.AFTER_ACTION, AUTOMATION.AFTER_PTOD].includes(stage.trigger_action_dependency_type_slug)" v-slot:tooltipBody>
                            <div style="width: 200px;">
                                {{ AUTOMATION.ANCHOR_STAGE_TOOLTIP }}
                            </div>
                        </template>
                        </hb-checkbox>
                    </hb-form>

                </hb-card>

                <p class="hb-font-header-2-medium mb-3">Delinquency Actions For This Stage</p>
                
                <v-container class="px-0 mt-0 pt-0">
                    
                    <v-row
                        no-gutters
                        class="pa-0 ma-0 applied-actions pl-5 pr-2 py-2 mb-3 d-flex align-center"
                        v-for="(action, index) in stage.Actions"
                        :key="index"
                    >
                        <v-col class="pa-0 mt-0" cols="12" sm="9">
                            <span class="hb-font-header-3-medium">{{action.name}}</span><span class="hb-font-header-3">{{actionDescriptor(action)}}</span></v-col>
                        <v-col class="pa-0 text-right d-flex flex-row justify-end" cols="12" sm="3" >
                            <a v-if="isEdit(action)" 
                                class="mr-2 d-flex align-center"
                                @click="editAction(action, index)"
                            >Edit</a>
                             <hb-btn
                                icon
                                active-state-off
                                @click="openWarningModal(action.dependant_triggers)"
                                v-if="action.dependant_triggers && action.dependant_triggers.length > 0"
                                >
                                mdi-close
                            </hb-btn>
                            <hb-btn
                                icon
                                active-state-off
                                @click="removeAction(index, true)"
                                v-else
                                >
                                mdi-close
                            </hb-btn>
                        </v-col>
                        
                        <v-col cols="12" v-if="getDescriptionError(action)" class="hb-text-error"><hb-icon color="#fb4c4c">mdi-alert</hb-icon> {{getDescriptionError(action)}}</v-col>
                    </v-row>
                    <v-row class="mx-0 mb-3 px-4 py-2 no-actions hb-text-light d-flex align-center">
                        <v-col class="pa-0 mt-0" cols="12" sm="8">Click the button to the right to add an additional action.</v-col>
                        <v-col class="pa-0 text-right" cols="12" sm="4">
                            <hb-btn color="secondary" @click="editAction" :disabled="editingAction">Add Action</hb-btn>
                        </v-col>

                    </v-row>
                </v-container>

            </div>
                <div class="main-side-info-panel px-6 py-1">

                
                    <v-container v-if="!editingAction">
                        <v-row class="pb-3">
                            <v-col class="pa-0">
                                
                                <span class="font-weight-medium">How to Add a Delinquency Stage</span><br /><br />
                                <span class="font-weight-medium">Step 1: Enter a Stage Name</span><br />
                                <p style="color: #637381">
                                Select a name that clearly describes what will take place in this stage.<br /><br />
                                Example: Apply Late Fee.</p>
                                
                                <span class="font-weight-medium">Step 2: Enter a Description</span><br />
                                <p style="color: #637381">
                                    Describe what is going to happen once this stage triggers.<br /><br />
                                    Example: Generate a fee due to a past due invoice.
                                </p>
                                
                                <span class="font-weight-medium">Step 3: Specify When the Stage Will Trigger</span><br />
                                <p style="color: #637381">
                                Select how many days after an account goes past due when a stage will trigger.
                                </p>
                                
                                <span class="font-weight-medium">Step 4: Add an Action</span><br />
                                <p style="color: #637381">
                                Add an action to this stage. The previous step defined what you want to happen, this actions you add will act as the tasks you want to carry out. 
                                </p>
                                <br />
                                <span class="font-weight-medium">Actions</span><br />
                                <p style="color: #637381">
                                    You are able to add as many actions to a stage as necessary. 
                                </p>
                                
                            </v-col>
                        </v-row>
                    </v-container>


                    <v-container v-if="editingAction">
                        <v-row class="mb-n2">
                            <span class="hb-font-body-medium d-flex align-center">
                                <hb-icon @click="resetFields" color="#101318" class="ml-n2 hand-pointer">mdi-chevron-left</hb-icon>
                                <template v-if="selectedAction">Edit </template>
                                <template v-else>Add </template>
                                Action
                            </span>
                        </v-row>
                        <v-row>
                            
                            <v-select
                                :items="actionTypesList"
                                :disabled="selectedAction && !!selectedAction.action_type_id"
                                v-model="selectedAction"
                                item-text="name"
                                return-object
                                label="Select Action"
                                id="actions"
                                name="actions"
                                hide-details
                                single-line
                                flat
                                class="input-font-size"
                            ></v-select>
                        </v-row>
                        <v-row class="pt-2 night-light-color" v-if="selectedAction && (selectedAction.label == 'deny_access' || selectedAction.label == 'overlock')">
                            Access is restored on payment that brings account current.
                        </v-row>
                        <v-row class="pt-2 night-light-color" v-if="selectedAction && (selectedAction.label == 'schedule_auction')">
                            Cut lock, inventory space, search title &amp; schedule auction.
                        </v-row>


                        <template v-if="selectedAction && selectedAction.label == 'add_fee'">
                             <v-row>
                                <v-checkbox 
                                    v-model="Fee.recurring"
                                    label="Apply this action to every invoice during the delinquency process"
                                    id="fee_recurring"
                                    name="fee_recurring"
                                    data-vv-as="fee_recurring"
                                    data-vv-scope="add_fee"
                                    :hide-details="!errors.collect('add_fee.fee_recurring').length"
                                    :error-messages="errors.collect('add_fee.fee_recurring')"
                                >

                                </v-checkbox>
                            </v-row>
                            <v-row>
                                <v-select
                                    :items="products"
                                    v-model="Fee.product_id"
                                    v-validate="'required'"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Fee"
                                    id="name"
                                    name="name"
                                    data-vv-as="Fee"
                                    data-vv-scope="add_fee"
                                    :hide-details="!errors.collect('add_fee.name').length"
                                    :error-messages="errors.collect('add_fee.name')"
                                    single-line
                                    flat
                                    class="input-font-size"
                                ></v-select>
                            </v-row>
                        
                        </template>
                        
                        <template v-if="selectedAction && selectedAction.label == 'change_status'">
                             <v-row class="pt-0">
                
                                <v-select
                                    :items="lease_standings"
                                    v-model="changeStatus.action_value"
                                    v-validate="'required'"
                                    item-text="name"
                                    item-value="id"
                                    label="Choose Tenant Status"
                                    id="lease_standing"
                                    name="lease_standing"
                                    data-vv-as="Tenant Status"
                                    data-vv-scope="change_status"
                                    :hide-details="!errors.collect('change_status.lease_standing').length"
                                    :error-messages="errors.collect('change_status.lease_standing')"
                                    single-line
                                    flat
                                    class="input-font-size"
                                ></v-select>
                            </v-row>
                        </template>

                         <template v-if="selectedAction && selectedAction.label == 'generate_document'">     
                            <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Attachment.recurring"
                                    label="Apply this action to every invoice during the delinquency process"
                                    id="document_recurring"
                                    name="document_recurring"
                                    data-vv-as="document_recurring"
                                    data-vv-scope="generate_document"
                                    :hide-details="!errors.collect('generate_document.document_recurring').length"
                                    :error-messages="errors.collect('generate_document.document_recurring')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>

                            <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Attachment.include_alternate"
                                    label="Include Alternate Contact"
                                    id="document_include_alternate"
                                    name="document_include_alternate"
                                    data-vv-as="document_include_alternate"
                                    data-vv-scope="generate_document"
                                    :hide-details="!errors.collect('generate_document.document_include_alternate').length"
                                    :error-messages="errors.collect('generate_document.document_include_alternate')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>


                             <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Attachment.include_lien"
                                    label="Include Lien Holder"
                                    id="document_include_lien"
                                    name="document_include_lien"
                                    data-vv-as="document_include_lien"
                                    data-vv-scope="generate_document"
                                    :hide-details="!errors.collect('generate_document.document_include_lien').length"
                                    :error-messages="errors.collect('generate_document.document_include_lien')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>


                             <v-row class="pt-2">
                                <v-select
                                    :items="documents"
                                    v-model="Attachment.document_id"
                                    item-text="name"
                                    item-value="id"
                                    v-validate="'required'"
                                    label="Select Document"
                                    id="document_id"
                                    name="document_id"
                                    data-vv-as="document_id Method"
                                    data-vv-scope="generate_document"
                                    :no-data-text="documentText"
                                    :hide-details="!errors.collect('generate_document.document_id').length"
                                    :error-messages="errors.collect('generate_document.document_id')"
                                    single-line
                                    flat
                                    class="input-font-size"
                                    :loading="loadingDocumentList"
                                >
                                    <template v-slot:append-item>
                                        <div v-intersect="getDocumentTemplates"  class="d-flex justify-center">
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                v-if="loadingDocumentList"
                                            ></v-progress-circular>
                                        </div>
                                    </template>
                                </v-select>
                            </v-row>


                            <template v-for="(dm, i) in Attachment.DeliveryMethods">
                                <span :key="i" v-if="dm.active">
                                    <v-row class="pt-4">
                                        <v-col cols="4" class="pa-0 pt-1"><span class="font-weight-medium">Delivery Method:</span></v-col>
                                        <v-col cols="7" class="pa-0 px-1">
                                            <v-select
                                                :items="delivery_methods"
                                                @change="changeDeliveryMethod(i, dm.delivery_methods_id)"
                                                v-model="dm.delivery_methods_id"
                                                item-text="name"
                                                item-value="id"
                                                v-validate="'required'"
                                                label="Select"
                                                id="delivery_method"
                                                name="delivery_method"
                                                data-vv-as="Delivery Method"
                                                data-vv-scope="generate_document"
                                                :hide-details="!errors.collect('generate_document.delivery_method').length"
                                                :error-messages="errors.collect('generate_document.delivery_method')"
                                                single-line
                                                flat
                                                class="input-font-size mt-0 pt-0"
                                        ></v-select>
                                        </v-col>
                                        <v-col class="pa-0 pt-1" cols="1" @click="dm.active = 0"><hb-icon>mdi-close</hb-icon></v-col>
                                        
                                    </v-row>
                            
                                    <template class="ma-0 pa-0" v-if="(dm.delivery_methods_id && dm.subject) || dm.method == 'standard_email' || dm.method == 'registered_email'">
                                        <v-row class="pt-0 mt-n2">
                                            <div class="mt-4">
                                                <span>Search for an existing template from the  template manager library</span>
                                            <HbAutocomplete
                                                v-model="dm.template_id"
                                                :items="templateNamesEmail"
                                                v-validate="'required|max:45'"
                                                item-text='name'
                                                item-value='template_id'
                                                data-vv-scope="default"
                                                data-vv-name="autocomplete-box"
                                                data-vv-as="Autocomplete Box"
                                                :error="errors.has('default.autocomplete-box')"
                                                placeholder="Select Template"
                                                class="mt-2"
                                                box
                                                @change="appendtemplate($event,'generate_document',i)"
                                                @click:clear="clearTemplate($event,'generate_document',i)"
                                            />
                                            </div>
                                            <v-text-field
                                                label="Subject Line"
                                                v-model="dm.subject"
                                                v-validate="'required|max:78'"
                                                id="attachment_subject"
                                                name="attachment_subject"
                                                data-vv-as="Subject"
                                                data-vv-scope="generate_document"
                                                :hide-details="!errors.collect('generate_document.attachment_subject').length"
                                                :error-messages="errors.collect('generate_document.attachment_subject')"
                                                single-line
                                                flat
                                                class="input-font-size"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="pt-4" v-show="false">
                                            <v-textarea
                                                label="Email Content"
                                                v-model="dm.message"
                                                v-validate="'required'"
                                                id="attachment_message"
                                                name="attachment_message"
                                                :hide-details="!errors.collect('generate_document.attachment_message').length"
                                                :error-messages="errors.collect('generate_document.attachment_message')"
                                                flat
                                                rows="6"
                                                class="input-font-size"
                                                @focus="handleTextAreaFocus(dm)"
                                            ></v-textarea>
                                        </v-row>
                                        <v-row cols="12" class="mr-0">
                                            <rich-text-editor 
                                                v-model="dm.message"
                                                :value="dm.message"
                                                :ref="`richTextEditor_${i}`"
                                                prose-height="250px"
                                                @focus="handleRichTextFocus(dm , i)"
                                                :hasBorder="true"
                                            />
                                            <!-- <div v-else class="readonly-html-content">
                                                    <div v-html="getTemplateHtml(dm.message)">
                                                    </div>
                                                </div> -->
                                        </v-row>
                                    </template>
                                    <v-row class="pt-2" v-if="dm.method == 'standard_email' || dm.method == 'standard_sms' || dm.method == 'registered_email'">
                                        <hb-btn color="secondary" @click="handleAddMergeClick(dm , i)">
                                            Add Merge Fields
                                        </hb-btn>
                                    </v-row>
                                </span>


                            </template>



                            <v-row class="pt-4">
                                <hb-link @click="addDeliveryMethod('attachment')" color="primary">+ Add Delivery Method</hb-link>
                            </v-row>

                    
                            
                    
                        </template>
                
                        <template v-if="selectedAction && selectedAction.label == 'send_message'">     
                            <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Message.recurring"
                                    label="Apply this action to every invoice during the delinquency process"
                                    id="message_recurring"
                                    name="message_recurring"
                                    data-vv-as="message_recurring"
                                    data-vv-scope="send_message"
                                    :hide-details="!errors.collect('send_message.message_recurring').length"
                                    :error-messages="errors.collect('send_message.message_recurring')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>

                            <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Message.include_alternate"
                                    label="Include Alternate Contact"
                                    id="message_include_alternate"
                                    name="message_include_alternate"
                                    data-vv-as="message_include_alternate"
                                    data-vv-scope="send_message"
                                    :hide-details="!errors.collect('send_message.message_include_alternate').length"
                                    :error-messages="errors.collect('send_message.message_include_alternate')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>


                             <v-row class="pt-2">
                                <v-checkbox 
                                    v-model="Message.include_lien"
                                    label="Include Lien Holder"
                                    id="message_include_lien"
                                    name="message_include_lien"
                                    data-vv-as="message_include_lien"
                                    data-vv-scope="send_message"
                                    :hide-details="!errors.collect('send_message.message_include_lien').length"
                                    :error-messages="errors.collect('send_message.message_include_lien')"
                                    single-line
                                    flat
                                    class="input-font-size mt-0"
                                >

                                </v-checkbox>
                            </v-row>
                            <template v-for="(m, i) in Message.DeliveryMethods">
                                <span :key="i" v-if="m.active">
                                    <v-row class="pt-4">
                                        <v-col cols="4" class="pa-0 pt-1"><span class="font-weight-medium">Delivery Method:</span></v-col>
                                        <v-col cols="7" class="pa-0 px-1">
                                            <v-select
                                                :items="message_methods"
                                                @change="changeMessageDeliveryMethod(i, m.delivery_methods_id)"
                                                v-model="m.delivery_methods_id"
                                                item-text="name"
                                                item-value="id"
                                                v-validate="'required'"
                                                label="Select"
                                                id="message_delivery_method"
                                                name="message_delivery_method"
                                                data-vv-as="Delivery Method"
                                                data-vv-scope="send_message"
                                                :hide-details="!errors.collect('send_message.message_delivery_method').length"
                                                :error-messages="errors.collect('send_message.message_delivery_method')"
                                                single-line
                                                flat
                                                class="input-font-size mt-0 pt-0"
                                        ></v-select>
                                        </v-col>
                                        <v-col class="pa-0 pt-1" cols="1" @click="m.active = 0"  v-if="i > 0"><hb-icon>mdi-close</hb-icon></v-col>
                                        
                                    </v-row>


                                    <template v-if="(m.delivery_methods_id && m.isSMS === true) || m.method == 'standard_sms'">
                                        <v-row class="pt-4">
                                            <div>
                                                <span>Search for an existing template from the  template manager library</span>
                                            <HbAutocomplete
                                                v-model="m.template_id"
                                                :items="templateNames"
                                                v-validate="'required|max:45'"
                                                item-text='name'
                                                item-value='template_id'
                                                data-vv-scope="default"
                                                data-vv-name="autocomplete-box"
                                                data-vv-as="Autocomplete Box"
                                                :error="errors.has('default.autocomplete-box')"
                                                placeholder="Select Template"
                                                class="mt-2"
                                                box
                                                @change="appendtemplate($event,'standard_sms',i)"
                                                @click:clear="clearTemplate($event,'standard_sms',i)"
                                            />
                                            </div>
                                            <v-textarea
                                                label="SMS Content"
                                                v-model="m.message"
                                                id="message_sms"
                                                v-validate="'required'"
                                                name="message_sms"
                                                data-vv-as="SMS Message"
                                                data-vv-scope="send_message"
                                                :hide-details="!errors.collect('send_message.send_message').length"
                                                :error-messages="errors.collect('send_message.send_message')"
                                                flat
                                                rows="4"
                                                class="input-font-size"
                                                @focus="handleTextAreaFocus(m, i)"
                                                :ref="`textAreaEditor_${i}`"
                                            ></v-textarea>
                                            <!-- <div v-else class="readonly-html-content">
                                                    <div v-html="getTemplateHtml(m.message)">
                                                    </div>
                                                </div> -->
                                        </v-row>

                                    </template>
                            
                                    <template v-if="(m.delivery_methods_id && m.subject && m.isSMS === false) || m.method == 'standard_email' || m.method == 'registered_email'">
                                        <v-row class="pt-4">
                                            <div class="">
                                                <span>Search for an existing template from the  template manager library</span>
                                            <HbAutocomplete
                                                v-model="m.template_id"
                                                 :items="templateNamesEmail"
                                                item-text='name'
                                                item-value='template_id'
                                                v-validate="'required|max:45'"
                                                data-vv-scope="default"
                                                data-vv-name="autocomplete-box"
                                                data-vv-as="Autocomplete Box"
                                                :error="errors.has('default.autocomplete-box')"
                                                placeholder="Select Something"
                                                class="mt-2"
                                                single-line
                                                flat
                                                box
                                                @change="appendtemplate($event,'standard_email',i)"
                                                @click:clear="clearTemplate($event,'standard_email',i)"
                                            />
                                            </div>
                                            <v-text-field
                                                label="Subject Line"
                                                v-model="m.subject"
                                                v-validate="'required|max:78'"
                                                id="message_subject"
                                                name="message_subject"
                                                data-vv-as="Subject"
                                                data-vv-scope="send_message"
                                                :hide-details="!errors.collect('send_message.message_subject').length"
                                                :error-messages="errors.collect('send_message.message_subject')"
                                                single-line
                                                flat
                                                class="input-font-size"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="pt-4" v-show="false">
                                            <v-textarea
                                                label="Email Content"
                                                v-model="m.message"
                                                v-validate="'required'"
                                                id="message_message"
                                                name="message_message"
                                                :hide-details="!errors.collect('send_message.message_message').length"
                                                :error-messages="errors.collect('send_message.message_message')"
                                                flat
                                                rows="6"
                                                class="input-font-size"
                                                @focus="handleTextAreaFocus(m)"
                                            ></v-textarea>
                                        </v-row>
                                        <v-row class="hb-table-row">
                                            <v-col class="hb-table-value pl-0">
                                                <rich-text-editor
                                                   :ref="`richTextEditor_${i}`"
                                                   v-model="m.message"
                                                   prose-height="250px"
                                                   @focus="handleRichTextFocus(m, i)"
                                                   :hasBorder="true"
                                                  
                                                />
                                                <!-- <div v-else class="readonly-html-content">
                                                    <div v-html="getTemplateHtml(m.message)">
                                                    </div>
                                                </div> -->
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <v-row class="pt-2" v-if="m.method == 'standard_email' || m.method == 'standard_sms' || m.method == 'registered_email' || m.delivery_methods_id">
                                        <hb-btn color="secondary" @click="handleAddMergeClick(m , i)">
                                               Add Merge Fields
                                        </hb-btn>
                                    </v-row>
                                </span>


                            </template>



                            <v-row class="pt-4">
                                <hb-link @click="addDeliveryMethod('message')" color="primary">+ Add Delivery Method</hb-link>
                            </v-row>

                    
                            
                    
                        </template>

                        <template v-if="selectedAction && selectedAction.label == 'create_task'">
                    
                            <v-row>
                            <v-checkbox 
                                v-model="Task.recurring"
                                label="Apply this action to every invoice during the delinquency process"
                                id="task_recurring"
                                name="task_recurring"
                                data-vv-as="task_recurring"
                                data-vv-scope="create_task"
                                :hide-details="!errors.collect('create_task.fee_recurring').length"
                                :error-messages="errors.collect('create_task.fee_recurring')"
                                single-line
                                flat
                                class="input-font-size"
                            >

                            </v-checkbox>
                        </v-row>
                        <v-row class="pt-2">

                            <v-select
                                :items="event_types"
                                v-model="Task.event_type_id"
                                v-validate="'required'"
                                item-text="name"
                                item-value="id"
                                label="Task Name"
                                id="event_id"
                                name="event_id"
                                data-vv-as="Task Name"
                                data-vv-scope="create_task"
                                :hide-details="!errors.collect('create_task.event_id').length"
                                :error-messages="errors.collect('create_task.event_id')"
                                single-line
                                flat
                                class="input-font-size"
                            ></v-select>
                        </v-row>
                            <v-row class="pt-2">
                            <v-textarea
                                label="Details"
                                v-model="Task.details"
                                v-validate="'required'"
                                id="details"
                                name="details"
                                data-vv-as="Task Details"
                                data-vv-scope="create_task"
                                :hide-details="!errors.collect('create_task.details').length"
                                :error-messages="errors.collect('create_task.details')"
                                single-line
                                flat
                                rows="1"
                                class="input-font-size"
                            ></v-textarea>
                        </v-row>
                        <v-row class="pt-2">
                            <v-select
                                :items="admins"
                                v-validate="'required'"
                                v-model="Task.contact_id"
                                item-text="name"
                                item-value="id"
                                label="Assigned to"
                                id="assigned_to"
                                name="assigned_to"
                                :hide-details="!errors.collect('create_task.assigned_to').length"
                                :error-messages="errors.collect('create_task.assigned_to')"
                                single-line
                                flat
                                class="input-font-size"
                            ></v-select>
                        </v-row>
                        </template>
               
                       
                        <v-row class="py-5 d-flex align-center">
                            
                            <hb-btn
                                color="primary"
                                :disabled="selectedAction == null"
                                @click="addAppliedActions"
                                class="mr-2"
                            >
                                <template v-if="selectedAction && !!selectedAction.action_type_id">Update</template>
                                <template v-else>Add</template> Action
                            </hb-btn>


                            <a v-if="selectedAction &&editingAction" color="primary" class="mr-2" @click="resetFields">Cancel</a>
                        </v-row>
                        
                    </v-container>

                   
                
            </div>
        </div>

               
        <div class="new-tenant-walkthrough-footer">
            <v-row class="mx-0">
                <v-col class="pt-4">
                    <help-juice />
                </v-col>
                <v-col md="11" class="d-flex align-center justify-end pr-sm-4">
                    <a class="mr-3" @click="close(true)">Cancel</a>
                    <hb-btn color="primary" :disabled="stageSaveInProgress" :loading="stageSaveInProgress" @click="saveStage()">Save Stage</hb-btn>
                </v-col>
            </v-row>
        </div>
        <stage-warning v-model="showSaveStageModal" :title="warningTitle" :description="warningDescription"
            :disabled="showLoader" v-if="showSaveStageModal" @close="confirmStatus()" @ok="confirmStatus(true)">
        </stage-warning>
        <delete-stage-action-warning v-model="show_delete_stage" :disabled="false"
            v-if="show_delete_stage" :allow_to_delete_trigger="allow_to_delete_trigger"  @close="closeDeleteStage()" @confirmDeleteStage="closeDeleteStage()">
        </delete-stage-action-warning>
        <hb-modal size="medium" confirmation title="Warning" v-model="anchorStageWarning" @close="cancelConfirm()">
            <template v-slot:content>
                <div class="py-4 px-6">
                    There is already an anchor stage <strong>{{ hasAnotherAnchorStage?.name }}</strong>, if you set this stage as anchor. The previous will be removed.
                    <br/><br/>
                    Are you sure you want to continue?     
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="anchorStageWarning = false">Yes</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>


<script type="text/babel">
import { mapGetters, mapActions, mapMutations } from "vuex";
import api from "../../../assets/api.js";
import MergeFields from "../../includes/MergeFields";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import RichTextEditor from '../../assets/RichTextEditor.vue';
import StageWarning from "./StageWarningModal.vue";
import AUTOMATION from '@/constants/automation.js';
import SETTINGS from '@/constants/setting.js';
import { isEqual } from "lodash";
import DeleteStageActionWarning from '../Automations/DeleteStageActionWarningModal.vue';
export default {
    name: "ManageStage",
    data() {
        return {
            open_sections: ["gate"],
            showDeliveryMethods: false, 
            selectedAction: null,
            appliedActions: [],
            manageAction:false,
            documents: [],
            delivery_method: '', 
            stage: {
                id: null,
                name: "",
                description: "",
                trigger_reference: "past_due",
                start: "",
                repeat: "",
                max_repeat: "",
                deny_access: "",
                overlock: "",
                lease_standing_id: "",
                trigger_group_id: "",
                apply_to_all: false,
                Actions: [],
                is_anchor_stage: 0
            },
            Attachment: {
                document_id: null,
                document_name: null,
                recurring: false,  
                include_alternate: false,
                include_lien: false, 
                DeliveryMethods: []
            },
            Message: {
                recurring: false,  
                include_alternate: false,
                include_lien: false, 
                DeliveryMethods: []
            },
            Task: {
                event_type_id: null,
                contact_id: null,
                todo: 1,
                details: null,
                recurring: false
            },
            changeStatus: {
                action_value: null,
                
            },
            Fee: {
                product_id: null,
                recurring: false            
            },
            editActionIndex: null,
            admins: [],
            products: [],
            showMergeFields: false,
            editingAction: false,
            event_types: [],
            lease_standings: [],
            editActionIndex: null,
            groupId : '',
            delivery_methods: [],
            message_methods: [],
            configurable_events_types: [
                //"auction_day", 
                // "lock_cut", 
                // "space_inventory", 
                // "auction_notice_advertisement", 
                //"auction_payment", 
                //"lien_notices", 
                "collection_calls",
                "task", 
                //"gate_code_issue"
            ],
            loadingDocumentList: false,
            allowScrollRequest: true,
            documentText: 'Loading documents...',
            templateNames:[],
            templateNamesEmail:[],
            templateName:'',
            isTriggeredByButton: false,
            debounceTimeout: null,
            activeMessageObj: null,
            currentRichTextIndex: null,
            saveChanges: false,
            needSaveStage: true,
            showSaveStageModal: false,
            warningTitle: '',
            warningDescription: '',
            showLoader: false,
            isOpened: false,
            oldStage: {},
            stageTimeFrame: '',
            stageTimeFrames: [],
            stageEvents: '',
            show_delete_stage: false,
            allow_to_delete_trigger: true,
            stageSaveInProgress: false,
            anchorStageWarning: false,
            isPtodEnabled: false,
            stageTriggerActionDependencyTypes: [],
        }
    },
    props: ['selected_stage', 'group_id', 'isOps', 'properties', 'automation_group_id', 'property_id', 'automation' , 'sectionIdentifier'],
    mixins: [notificationMixin],

    async created() {
        console.log(this.selected_stage, "MANAGE STAGE");
        console.log(this.automation, 'AUTOMATION');
        this.AUTOMATION = AUTOMATION;
        this.SETTINGS = SETTINGS;
        // fetch latest PTOD settings 
        await this.$store.dispatch('settingsStore/fetchPaidToDateSettings');
        this.isPtodSettingEnabled();
        // Setting the default dropdown values
        this.stageTimeFrames = this.timeFrames;
        this.stageTriggerActionDependencyTypes = !this.isPtodEnabled ? this.triggerActionDependencyTypes.filter(dependency => dependency.slug !== AUTOMATION.AFTER_PTOD): this.triggerActionDependencyTypes;
        if(this.selected_stage && this.selected_stage.id ){    
            this.stage = JSON.parse(JSON.stringify(this.selected_stage))
            this.oldStage = JSON.parse(JSON.stringify(this.stage));
            if (this.stage && parseInt(this.stage.start) > 0){
                this.stageTimeFrame = AUTOMATION.DAYS;
            }else if( this.selectedAutomationType?.name == AUTOMATION.MARKETING_AUTOMATION && (this.stage.start_hours || this.stage.start_minutes)){
                this.stageTimeFrame = AUTOMATION.HOURS_MIN
            }else if(this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_PTOD){
                this.stageTimeFrame = '';  
            }else {
                this.stageTimeFrame = AUTOMATION.IMMEDIATELY;  
            }
        }
        if(this.automation?.trigger_group_id)
            this.$store.dispatch('automationStore/fetchAllEvents', {trigger_group_id: this.automation.trigger_group_id, trigger_id: this.stage?.id});
        // Filtering the default dropdown values if no stage exists in the workflow.
        if(this.automation?.trigger_group_stages == 0){
            this.stageTriggerActionDependencyTypes = this.triggerActionDependencyTypes.filter(dependency => (dependency.slug !== AUTOMATION.AFTER_ACTION && dependency.slug !== AUTOMATION.AFTER_PTOD));
        }
        this.getActionTypes();
        this.setDocuments([]);
        this.fetchDocuments();
        this.fetchAdmins();
        this.fetchEventTypes();
        this.fetchLeaseStandings();
        this.fetchProducts();
        this.fetchDeliveryMethods();
        this.fetchMessageDeliveryMethods();
       
    }, 
    components:{
        MergeFields,
        'rich-text-editor': RichTextEditor,
        StageWarning,
        DeleteStageActionWarning
    },
    mounted() {
        this.getBaseTemplateData()
    },
    computed: {
        ...mapGetters({
            triggers: "triggersStore/triggers",
            active: "triggersStore/active",
            actionTypes: "triggersStore/actionTypes",
            getUserData: "authenticationStore/getUserData",
            getDocuments: 'documentsStore/getDocuments',
            getDeliveryMethods: 'documentsStore/getDeliveryMethods',
            getMessageDeliveryMethods: 'documentsStore/getMessageDeliveryMethods',
            getDeliveryMethod: 'documentsStore/getDeliveryMethod',
            getMessageDeliveryMethod: 'documentsStore/getMessageDeliveryMethod',
            triggerActionDependencyTypes: 'automationStore/getTriggerActionDependencyTypes',
            timeFrames: 'automationStore/getTimeFrames',
            allEvents: 'automationStore/getAllEvents',
            ptodSettings: 'settingsStore/ptod',
            triggerMergeFields: 'addMergeFieldsStore/triggerMergeFields',
            selectedMergeField: 'addMergeFieldsStore/selectedMergeField',
            actionSource: 'addMergeFieldsStore/actionSource',
            addMergeButtonClickFlag: 'addMergeFieldsStore/addMergeButtonClickFlag',
            allTriggers: "automationStore/getAllTriggers",
        }),
        eventsComputedPlaceholder() {
            return this.stage.parent_action_id ? '' : 'Search Event';
        },
        triggerActionsFound() {
            return this.stage && this.stage.Actions.length; 
        },
        actionTypesList(){
            let types = JSON.parse(JSON.stringify(this.actionTypes));

             //Added by BCT Team
            if(this.isOps == true){
                let action = this.actionTypes;
                types = types.filter(action => (action.name !== 'Create Task' &&  action.name != 'Generate Document'));
            }
            types.sort((a, b) => a.label.localeCompare(b.label));
            return types; 
        },
        allStageEvents(){
            let events = this.allEvents.length ? this.allEvents.map(event => ({
                ...event,
                full_action_name: this.hasObjValue(event, 'description') 
                    ? event.full_action_name + `: <span style="color:#637381">${event.description}</span>`
                    : event.full_action_name
                }))
            : [];
            // set the default event if parent action exists.
            if(this.stage?.parent_action_id && events.length)
                this.stageEvents = events.find(event => String(event.action_id).toLowerCase() === String(this.stage?.parent_action_id).toLowerCase());
            return events;
        },
        hasAnotherAnchorStage(){
            return this.allTriggers.length && this.allTriggers.find(item => item.is_anchor_stage === 1 &&  item?.id !== this.stage?.id)
        }
    },
    destroyed() { 
        this.$store.commit('automationStore/setAllEvents', []);
    },
    methods:{
        handleAnchorStageChange(event){
            this.stage.is_anchor_stage = event ? 1 : 0;
            if (event && this.hasAnotherAnchorStage?.id) {
                this.anchorStageWarning = true;
            }
        },
        cancelConfirm(){
            this.stage.is_anchor_stage = 0;
        },
        handleAddMergeClick(messageObj , activeindex){
            this.activeMessageObj = messageObj
            this.currentRichTextIndex = activeindex
            this.setTriggerMergeFields({
            actionSource: this.sectionIdentifier,
            addMergeButtonClickFlag: true
        })      
         },

        handleRichTextFocus(messageObj, activeindex) {
            this.activeMessageObj = messageObj
            this.currentRichTextIndex = activeindex
            if (this.triggerMergeFields === true) {
                this.setTriggerMergeFields({
                    actionSource: this.sectionIdentifier
                })
            }
        },

        handleTextAreaFocus(messageObj, activeindex) {
            this.activeMessageObj = messageObj
            this.currentRichTextIndex = activeindex
            if (this.triggerMergeFields === true) {
                this.setTriggerMergeFields({
                    actionSource: this.sectionIdentifier
                })
            }
        },


        insertMergeField(txt) {
            if (this.actionSource === this.sectionIdentifier) {
                if (this.addMergeButtonClickFlag) {                   
                    if(this.activeMessageObj?.method === 'standard_email' || this.activeMessageObj?.method  === 'registered_email'){
                        const editorInstance = this.$refs[`richTextEditor_${this.currentRichTextIndex}`][0].editor;
                    if (editorInstance) {
                        editorInstance.commands.insertContent(txt);
                        this.activeMessageObj.message = editorInstance.getHTML();
                    } else {
                        console.error("Editor instance not found!");
                    }
                    }
                    else{
                        const textarea = this.$refs[`textAreaEditor_${this.currentRichTextIndex}`][0].$el.querySelector('textarea');
                        if (textarea) {
                            const start = textarea.selectionStart;
                            const end = textarea.selectionEnd;
                            // Insert the text at the cursor position
                            const before = this.activeMessageObj.message.slice(0, start);
                            const after = this.activeMessageObj.message.slice(end);
                            this.activeMessageObj.message = before + txt + after;

                                // Move the cursor to the end of the inserted text
                                this.$nextTick(() => {
                                textarea.setSelectionRange(start + txt.length, start + txt.length);
                                textarea.focus();
                                });
                            } else {
                                console.error("Textarea element not found!");
                            }
                    }
                    this.setFilterInput({
                        filterInput: ""
                    })
                    this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
                }
                else {
                    const charReplacedMessage = this.activeMessageObj.message.replace(/\[[^\]]+$/, txt);
                    this.activeMessageObj.message = charReplacedMessage;
                    if (this.$vuetify.breakpoint.xs) {
                        this.resetMergeFieldParameters()
                    }
                    this.setFilterInput({
                        filterInput: ""
                    })
                    this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
                }
            }
        },
        getTemplateHtml(content) {
            try {
                let temp_content = JSON.parse(content)
                return temp_content.content
            } catch (error) {
                return content
            }
        },
         ...mapActions({
            getActionTypes: "triggersStore/getActionTypes",
            fetchDocumentsData: 'documentsStore/fetchDocuments',
            fetchDeliveryMethodsData: 'documentsStore/fetchDeliveryMethods',
            fetchMessageDeliveryMethodsData: 'documentsStore/fetchMessageDeliveryMethods',
            setTriggerMergeFields: 'addMergeFieldsStore/setTriggerMergeFields',
            setActionSource: 'addMergeFieldsStore/setActionSource',
            setFilterInput: "addMergeFieldsStore/setFilterInput",
            resetMergeFieldParameters: "addMergeFieldsStore/reset",
        }),
        ...mapMutations({
            setDocuments: 'documentsStore/setDocuments'
        }),
        isEdit(action){
            if(action.label == 'generate_document' || action.label == 'change_status' || action.label == 'add_fee' || action.label == 'send_message'  ){
                return true;
            }
            return false;
        },
        async getBaseTemplateData() {
            try {
                const params = new URLSearchParams({ get_only_active_templates: "true" });
                if (this.property_id) {
                    params.append("property_id", this.property_id);
                }
                await api.get(this, `${api.TEMPLATE_MANAGER}templates?${params.toString()}`).then((res) => {
                    this.templateNames = res.templates.filter(template => template.type === 'sms').map(template => ({ name: template.name, template_id: template.id }));
                    this.templateNamesEmail = res.templates.filter(template => template.type === 'email').map(template => ({ name: template.name, template_id: template.id }));
                })
            } catch (error) {
                console.error(`Error fetching templates`, error);
                throw error; // Rethrow the error to propagate it up
            }
        },
        async appendtemplate(id, type, index) {
            await api.get(this, api.TEMPLATE_MANAGER + `template?template_id=${id}`).then((res) => {
                if (type == 'standard_email') {               
                    if (res[0].template.body && res[0].template.subject) {
                        const newContent = {
                            template_id: id,
                            content: res[0].template.body,
                            index
                        };
                        this.Message.DeliveryMethods[index].message = newContent.content;
                        this.Message.DeliveryMethods[index].subject = res[0].template.subject
                    }
                    else {
                        this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
                    }
                }
                if (type == 'generate_document') {
                    if (res[0].template.body && res[0].template.subject) {
                        const newContent = {
                            template_id: id,
                            content: res[0].template.body,
                            index
                        };
                        this.Attachment.DeliveryMethods[index].subject = res[0].template.subject
                        this.Attachment.DeliveryMethods[index].message = newContent.content;;
                    }
                    else {
                        this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
                    }
                }
                if (type == 'standard_sms') {
                    if (res[0].template.smsContent) {
                        const newContent = {
                            template_id: id,
                            content: res[0].template.smsContent,
                            index
                        };
                        this.Message.DeliveryMethods[index].message = newContent.content;;
                    }
                    else this.showMessageNotification({ type: 'error', description: "Can't Attach Email Template to SMS Field" });
                }
            })


        },

        clearTemplate(id, type, index) {
            const dataSource = type === "generate_document" ? this.Attachment : this.Message;
            const deliveryMethod = dataSource?.DeliveryMethods?.[index];

            if (!deliveryMethod) return;

            const fieldsToClear = {
                standard_email: ["message", "subject"],
                standard_sms: ["message"],
                generate_document: ["message", "subject"]
            };

            fieldsToClear[type]?.forEach(field => {
                deliveryMethod[field] = "";
            });
        },
        async fetchDeliveryMethods(){
            await this.fetchDeliveryMethodsData(this.property_id);
            this.delivery_methods = await this.getDeliveryMethods;
        },
        async fetchMessageDeliveryMethods(){
            await this.fetchMessageDeliveryMethodsData(this.property_id);
            this.message_methods = await this.getMessageDeliveryMethods;
        },
        async addDeliveryMethod(type){
            if (type === "message"){
                this.Message.DeliveryMethods.push({
                    method: '',
                    subject: '',
                    active: 1, 
                    message: '', 
                })
            }else if(type === 'attachment'){
                this.Attachment.DeliveryMethods.push({
                    method: '',
                    subject: '',
                    active: 1, 
                    message: "[Tenant.FirstName] [Tenant.LastName],", 
                })
            }
        },
        async changeDeliveryMethod(index, id){
            await this.$store.dispatch('documentsStore/getDeliveryMethodById', id);
            if(this.getDeliveryMethod.delivery_type !== "email"){
                this.Attachment.DeliveryMethods[index].delivery_methods_id = id;
                this.Attachment.DeliveryMethods[index].method = '';
                this.Attachment.DeliveryMethods[index].subject = '';
                this.Attachment.DeliveryMethods[index].active = 1;
                this.Attachment.DeliveryMethods[index].message = "[Tenant.FirstName] [Tenant.LastName],";
            }
            if(this.getDeliveryMethod.gds_key){
                this.Attachment.DeliveryMethods[index].method = this.getDeliveryMethod.gds_key;
            }
        },
        async changeMessageDeliveryMethod(index, id){
            await this.$store.dispatch('documentsStore/getMessageDeliveryMethodById', id);

            if(this.getMessageDeliveryMethod.delivery_type == "sms"){
                this.Message.DeliveryMethods[index].isSMS = true;
                this.Message.DeliveryMethods[index].message = "";
            }else{
                this.Message.DeliveryMethods[index].isSMS = false;
                this.Message.DeliveryMethods[index].message = "";
            }
            if(this.getMessageDeliveryMethod.gds_key){
                this.Message.DeliveryMethods[index].method = this.getMessageDeliveryMethod.gds_key;
            }
        },
        removeDeliveryMethod(i){
            if(selectedAction.label == 'generate_document'){
                this.Attachment.DeliveryMethods.splice(i, 1); 
            } else if(selectedAction.label == 'send_message') {
                this.Messages.DeliveryMethods.splice(i, 1); 
            }
        }, 
        close(refresh){
            this.resetFields();
            this.$emit('close');
            if(refresh) this.$emit('refresh'); 
        }, 
        getDescriptionError(action){
            switch(action.label){
                case "add_fee":
                    if(this.products.length && action.product_id && !this.products.find(p => p.id === action.product_id)){ 
                        return "Error: Could not locate product.";
                    } 
                    return false;
                    break;
                case "generate_document":
                    
                    if(!this.loadingDocumentList && this.documents.length &&  action.document_id && !this.documents.find(p => p.id == action.document_id)){
                        return "Error: Could not locate document.";
                    }
                    return false;
                    break;
                case "change_status":
                    break;
                default:
                    return false
            }
        }, 
        actionDescriptor(action){
            let description = "";
            switch(action.label){
                case "add_fee":
                    if(action.product_id){
                        let p = this.products.find(p => p.id === action.product_id) ; 
                        if(p) {
                            description = ": " + p.name; 
                        }
                    }
                    break;
                case "generate_document":
                    //let d = this.documents.find(p => p.id === action.document_id); 
                    description = ": " + action.document_name; 
                    break;
                case "change_status":
                    let l = this.lease_standings.find(l => l.id === action.action_value); 
                    if(l) description = ": " + l.name; 
                    break;
            }
            return description; 
        }, 
        async fetchProducts() {
            let r = await api.get(this, api.PRODUCTS + "?search=late&category_type=delinquency"); 
            this.products = r.products.filter(el => el.category_type == "delinquency");
        },
        async fetchLeaseStandings() {
            let r =  await api.get(this, api.SETTINGS + "lease-standings"); 
            this.lease_standings = r.lease_standings.filter(s => s.name.toLowerCase() === 'active lien' || s.name.toLowerCase() === 'delinquent' );
        },
        async fetchEventTypes(){
            let r = await api.get(this, api.EVENTS + 'event-types?source=automation');
            this.event_types = r.event_types.sort((a, b) => a.name.localeCompare(b.name)).filter(a => this.configurable_events_types.find(et =>  et === a.slug));
        },
        async fetchAdmins(){
            let r = await api.get(this, api.ADMIN)
            this.admins = r.admins
                .filter(a => a.contact_id !== this.getUserData.id)
                .map(a => {
                    return {
                        id: a.contact_id,
                        name: a.first + " " + a.last
                    };
                }).sort((a, b) => a.name.localeCompare(b.name));
            this.admins.unshift({
                id: -1,
                name: "Everyone"
            });
            this.admins.unshift({
                id: this.getUserData.id,
                name: "Me"
            });
        },
        async getDocumentTemplates(entries, observer, isIntersecting){
            if (isIntersecting && this.allowScrollRequest && !this.loadingDocumentList) {
                this.fetchDocuments();
            }
        },
        async fetchDocuments() {
            this.loadingDocumentList = true;
            let path = '';
            let limit = 200;
            let offset = this.getDocuments.length == 0 ? 1 : Math.ceil(this.getDocuments.length / limit) + 1 ;
            if(this.properties?.length) {
                path = api.DOCUMENT.slice(0, -1) + `?type[]=delinquency&limit=${limit}&offset=${offset}${this.filterByProperties()}`;
            } else {
                path = api.DOCUMENT.slice(0, -1) + `?type[]=delinquency&limit=${limit}&offset=${offset}`;
            }
            let data = {
                component: this,
                params: path
            }
            try {
                const currentDocs = await this.fetchDocumentsData(data);
                if(currentDocs.length == 0) {
                    this.allowScrollRequest = false;
                } else {
                    this.allowScrollRequest =  true;
                }
                if(this.getDocuments.length === 0){
                    this.documentText = 'No data found.'
                }
                this.documents = this.getDocuments;
                this.loadingDocumentList = false;
            } catch(err) {
                this.showMessageNotification({ description: err });
                this.loadingDocumentList = false;
            }
        },
        filterByProperties() {
            let filteredProperties = '';
            this.properties?.map(p => {
                filteredProperties += `&property_ids[]=${p.id}`
            });
            return filteredProperties;
        },

        resetFields() {
            this.selectedAction = null;
            this.editingAction = false;
            this.editActionIndex = null;
            this.showMergeFields = false;
            this.saveChanges = false;
            this.isOpened = false;
            this.showSaveStageModal = false;
            this.stageSaveInProgress = false;
            this.Attachment = {
                document_id: null,
                document_name: null, 
                recurring: false,  
                name: null, 
                include_alternate: false,
                include_lien: false,
                DeliveryMethods: []
            };
            this.Message = {
                recurring: false,  
                name: null, 
                include_alternate: false,
                include_lien: false,
                DeliveryMethods: []
            };
            this.Task = {
                event_type_id: null,
                contact_id: null,
                recurring: false
            };
            this.changeStatus = {
                action_value: null
            };
            this.Fee = {
                product_id: null,
                recurring: false,  
            };
        },

        async addAppliedActions() {
            let status = await this.$validator.validateAll(
                this.selectedAction.label
            );
            console.log("status", status); 
            if (!status) return;
            
            if ( ["deny_access", "overlock", "deny_online_payments", "cancel_insurance", "space_inventory", "schedule_auction"].indexOf(this.selectedAction.label) >=0 ){
                let triggerFound = this.stage.Actions.find(tAction => tAction.label == this.selectedAction.label);
                if (triggerFound) {
                    this.showMessageNotification({ 
                        description: `${this.selectedAction?.name + ' action' || 'This Action ' } can't be added twice in same stage.`
                    });
                    // this.resetFields();
                    return;
                }
            }
            const labels = this.stage.Actions.map(e => e.label);
            if (this.selectedAction.label == "change_status" && labels.includes(this.selectedAction.label)){
                if(this.selectedAction && !!this.selectedAction.action_type_id) {
                    this.stage.Actions.filter(el => el.label !== "change_status")
                } else {
                    this.showMessageNotification({ 
                        description: `${this.selectedAction?.name + ' action' || 'This Action ' } can't be added twice in same stage.`
                    });
                    // this.resetFields();
                    return;
                }
            }

            this.editingAction = false;
            if (this.selectedAction && this.selectedAction.trigger_id) {
                this.removeAction(this.selectedAction.id, false);
            }
            else if(this.editActionIndex != null) {
                this.removeAction(this.editActionIndex, true);
            }

            let trigger_id = this.selectedAction.trigger_id ? this.selectedAction.trigger_id : null
            this.editActionIndex = null;
            let action = {
                action_type_id: this.selectedAction.action_type_id || this.selectedAction.id,
                id: this.selectedAction.trigger_id ? this.selectedAction.id : null,
                label: this.selectedAction.label,
                trigger_id: this.selectedAction.trigger_id,
                name: this.selectedAction.name
            }

            switch(this.selectedAction.label){
                case "change_status":
                    action.action_value = this.changeStatus.action_value;
                    break;
                case "create_task":
                    action.event_type_id = this.Task.event_type_id;
                    action.active = 1;
                    action.contact_id = this.Task.contact_id;
                    action.todo = 1;
                    action.details =  this.Task.details;
                    action.recurring = this.Task.recurring;
                    break;
                case "generate_document":
                    action.document_id = this.Attachment.document_id;
                    action.recurring = this.Attachment.recurring;
                    action.include_alternate = this.Attachment.include_alternate;
                    action.include_lien = this.Attachment.include_lien;
                    action.DeliveryMethods = this.Attachment.DeliveryMethods;
                    let doc = this.documents.find(d => d.id === this.Attachment.document_id); 
                    action.document_name = doc.name; 
                    action.active = 1;
                    break;
                case "send_message":
                    action.recurring = this.Message.recurring;
                    action.include_alternate = this.Message.include_alternate;
                    action.include_lien = this.Message.include_lien;
                    action.DeliveryMethods = this.Message.DeliveryMethods;
                    action.active = 1;
                    break;
                case "add_fee":
                    action.product_id = this.Fee.product_id;
                    action.recurring = this.Fee.recurring;
                    action.active = 1;
                    break;
            }

            this.stage.Actions.push(action);
            this.stage = Object.assign({}, this.stage, this.stage);
            this.resetFields();
        },

        async confirmStatus(confirm = false) {
            if(confirm){
                this.showLoader = true;
                this.saveChanges = false;
                await this.saveStage();
                this.showLoader = false; 
            }else{
                this.stageSaveInProgress = false;
                this.showSaveStageModal = false;
            }
        },
        async checkValidation(fieldName){
            await this.$validator.validate(fieldName);
        },
        async saveStage() {
            this.stageSaveInProgress = true;
            // validate the form
            let status = await this.$validator.validateAll('stage');
            if (!status) {
                this.stageSaveInProgress = false;
                return;
            }
            // Open the warning modal's based on the certain conditions
            let modified_properties = this.automation.applied_properties.find(properties => properties?.type !== AUTOMATION.STATUSES.PROPERTY_MODIFIED) || {};
            if (this.saveChanges) {
                await this.openWarningModals();
                return;
            }else if(Object.entries(this.automation || {}).length > 0 && modified_properties?.properties?.length > 1 && !this.isOpened && this.needSaveStage && !this.property_id){
                await this.openWarningModals();
                return;
            }else if (!this.needSaveStage) {
                this.resetFields();
                this.close(true);
                return;
            }
            let anchorStageTrigger = this.hasAnotherAnchorStage?.id;
            this.editingAction = false;
            this.stage.Actions.forEach((x,i) =>  x.contact_id = x.contact_id === -1? null : x.contact_id);
            var trigger = JSON.parse(JSON.stringify(this.stage));
            //trigger.start--;
            trigger.trigger_group_id = this.group_id;
            trigger.start = trigger.start === '' ? 0 : trigger.start;
            const excludedTypes = [AUTOMATION.AFTER_DELINQUENCY_START, AUTOMATION.AFTER_PTOD];
            trigger.parent_action_id = excludedTypes.includes(trigger.trigger_action_dependency_type_slug) ? null : this.stageEvents?.id;
            let trigger_action_dependency_type_id = this.stageTriggerActionDependencyTypes.find(item=> item.slug === this.stage.trigger_action_dependency_type_slug)?.id;
            let parent_trigger_id = this.allStageEvents.find(item => item.action_id === this.stageEvents?.id)?.trigger_id;
            trigger.parent_trigger_id = excludedTypes.includes(trigger.trigger_action_dependency_type_slug) ? null : parent_trigger_id;
            trigger.parent_action_name = excludedTypes.includes(trigger.trigger_action_dependency_type_slug) ? null : trigger.parent_action_name;
            trigger.trigger_action_dependency_type_id = trigger_action_dependency_type_id;
            
                let payload = {
                    trigger: trigger,
                    automation_group_id: this.automation_group_id,  
                    trigger_group_id: this.group_id,   
                    property_id: this.property_id,     
                    remove_anchor_trigger_id: (trigger.is_anchor_stage && anchorStageTrigger) ? anchorStageTrigger : null
                };
            try {

                let r = {};

                if (!trigger.id) { //this is when a new stage is added
                    r = await api.post(this, api.TRIGGERS, payload);
                } else {
                    r = await api.put(this, api.TRIGGERS + trigger.id, payload);  // if the stage is already there. 
                }

                this.showMergeFields = false;
                this.resetFields();
                this.close(true);

            } catch(err){
                this.stageSaveInProgress = false;
                this.showMessageNotification({ description: err })
            }
        },
        editAction(action, index) {
            this.saveChanges = false;
            if(action){
                if (action.label == "send_message") {
                    this.Message = JSON.parse(JSON.stringify(action));
                    this.Message.DeliveryMethods.map(  dm => {
                        this.$store.dispatch('documentsStore/getMessageDeliveryMethodById', dm.delivery_methods_id);
                        dm.isSMS = this.getMessageDeliveryMethod.delivery_type === 'sms' ? true : false;
                        return dm;
                    })
                } else if (action.label == "generate_document") {
                    this.Attachment = JSON.parse(JSON.stringify(action));
                } else if(action.label == "add_fee") {
                    this.Fee = JSON.parse(JSON.stringify(action));
                } else if(action.label == "create_task") {
                    if(!action.contact_id) {
                        action.contact_id = -1;
                    }
                    this.Task = JSON.parse(JSON.stringify(action));
                } else if(action.label == "change_status") {
                    this.changeStatus = JSON.parse(JSON.stringify(action));
                }
                
                this.selectedAction = JSON.parse(JSON.stringify(action));
                this.editActionIndex = index;
            }else{
              this.saveChanges = true;
            }
            this.editingAction = true;
        },
        removeAction(actionID, flag) {
            
            var selected = this.selectedAction;

            if(flag) {
                this.stage.Actions.splice(actionID, 1);
            } else {
                if(this.selectedAction && this.selectedAction.trigger_id) {
                    var index = this.stage.Actions.findIndex(t => t.id == this.selectedAction.id);
                    this.stage.Actions.splice(index, 1); 
                }
            }
            this.editActionIndex = null;
            this.editingAction = false;
        },
        updateConstantsText(originalText, obj = {}){
            let updatedText = originalText;
            if (Object.entries(obj).length > 0 && obj.key && obj.value) {
                const regex = new RegExp(`\\[${obj.key}\\]`, 'g');
                updatedText = originalText.replace(regex, obj.value);
            }
            return updatedText;
        },
        async openWarningModals(){
            let modified_properties = this.automation.applied_properties.find(properties => properties?.type !== AUTOMATION.STATUSES.PROPERTY_MODIFIED) || {};
            this.warningTitle = this.saveChanges ? this.AUTOMATION.STAGE_UNSAVED_WARNING_TITLE : this.automation.full_path;
            this.warningDescription = this.saveChanges ? this.AUTOMATION.STAGE_UNSAVED_WARNING_DESC : this.updateConstantsText(this.AUTOMATION.STAGE_PROPERTIES_WARNING_DESC, {key: 'applied_properties_count', value: modified_properties?.properties?.length});
            this.isOpened = this.saveChanges ? false : true;
            this.showSaveStageModal = true;
        },
        hasObjValue(obj, key) {
            return obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null && obj[key] !== '';
        },
        openWarningModal(triggers = []) {
            this.show_delete_stage = true;
            this.allow_to_delete_trigger = triggers.length == 0;
        },
        closeDeleteStage() {
            this.show_delete_stage = false;
        },
        isPtodSettingEnabled(){
            console.log("SETTINGS.ALLOW_PTOD:",SETTINGS.ALLOW_PTOD)
            const ptodSetting = this.ptodSettings.find(setting => setting.name === SETTINGS.ALLOW_PTOD);
            if (ptodSetting?.value) {
                this.isPtodEnabled = parseInt(ptodSetting.value) === 1;
            }
        }
    },
    watch: {
        selected_stage(stage){
            if(stage.id){
                this.stage = JSON.parse(JSON.stringify(stage))
            } else {
                this.resetFields();
            }
            
        },
        stage: {
            deep: true,
            handler(stage) {
                if (isEqual(stage, this.oldStage)) {
                    this.needSaveStage = false;
                }else{
                    this.needSaveStage = true;
                }
            }
        },
        stageTimeFrame(timeFrame) {
            if(timeFrame === AUTOMATION.IMMEDIATELY)
                this.stage.start = '';             
        },
        "stage.trigger_action_dependency_type_id"(trigger_action_dependency_type_id) {
            if(trigger_action_dependency_type_id){
                let currentTriggerActionSlug = this.stageTriggerActionDependencyTypes.find(dependency => dependency.id === this.stage?.trigger_action_dependency_type_id)?.slug;
                let anchorStage = this.oldStage.is_anchor_stage;
                if(currentTriggerActionSlug && this.stage.trigger_action_dependency_type_slug !== currentTriggerActionSlug){
                    this.stage.trigger_action_dependency_type_slug = currentTriggerActionSlug;
                }
                this.stageTimeFrames = ((this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_DELINQUENCY_START) || (this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_PTOD)) 
                    ? this.timeFrames.filter(timeFrame => timeFrame.slug !== AUTOMATION.IMMEDIATELY) 
                    : this.timeFrames;
                if(this.selectedAutomationType?.name == AUTOMATION.MARKETING_AUTOMATION) this.stageTimeFrames.push({ id: this.stageTimeFrames.length+1, slug: 'hours-minutes', content: 'Hours Minutes' })
 
                if((this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_DELINQUENCY_START || this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_PTOD) && this.stageTimeFrame === AUTOMATION.IMMEDIATELY)
                    this.stageTimeFrame = ''; 
                
                if((this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_DELINQUENCY_START) || (this.stage.trigger_action_dependency_type_slug === AUTOMATION.AFTER_PTOD))
                    this.stage.parent_action_id = this.stage.parent_action_name  = this.stage.parent_trigger_id = null;

                this.stage.is_anchor_stage = [AUTOMATION.AFTER_ACTION, AUTOMATION.AFTER_PTOD].includes(currentTriggerActionSlug) ? 0 : anchorStage;
            }
        },
        ptodSettings() { 
            this.isPtodSettingEnabled();
        },
        selectedMergeField(newValue) {
            if (newValue) {
                this.insertMergeField(newValue)
            }
        }
    }
}
</script>
<style scoped> 
.grayColor{
    color:#637381;
}
.navigation-panel-container{
    background: #f9fafb;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.panel-content{
    flex: 1; 
    display: flex;
    overflow: auto;
}
.new-tenant-walkthrough-footer{
    position: relative;
}

.no-actions {
    border: 1.6px dashed #c4cdd5;
    box-sizing: border-box;
    border-radius: 4px;
}
.main-panel-content {
    flex: 1;
    padding: 14px 24px 24px 24px; 
    overflow: auto;

}
.main-side-info-panel {
    flex: 0 0 420px; 
    
    height: 100%;
    background-color: #f9fafb;
    border-left: 1px solid #e1e6ea;
}
@media only screen and (max-width: 1024px) {
  .main-side-info-panel {
    flex: 0 0 320px; 
  }
}
.applied-actions {
    background: #ffffff;
    border: 1px solid #e0e3e8;
    box-sizing: border-box;
    border-radius: 8px;
}
.readonly-html-content{
    max-height: 300px; 
    max-width: 400px;
    overflow: auto;
}

</style>
