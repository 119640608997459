<template>
  <hb-form
    label="Apply to other space types"
    description="Select all other Space Types to apply these settings to"
  >
    <HbCombobox
      item-text="name"
      item-value="id"
      @input = "onInputChange" 
      v-model="internalSelectedSpaceTypes"
      select
      :items="spaceTypes"
      label="Select Space Types"
      :disabled="isPropertyLevel && useCorporateDefaults"
    >
    </HbCombobox>
  </hb-form>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
export default {
  name: "SpaceSelector",
  props: {
    selectedSpaceTypes: {
      type: Array,
    },
    useCorporateDefaults: {
      type: Boolean,
      default: false,
    },
    isPropertyLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOptions: [],
      spaceTypes: [],
      internalSelectedSpaceTypes: [], 
    };
  },
  async created() {
    this.spaceTypes = cloneDeep(this.unitTypes);
    this.internalSelectedSpaceTypes= this.selectedSpaceTypes ? this.selectedSpaceTypes : [];
  },
  computed: {
    ...mapGetters({
      unitTypes: "filterStore/unit_type",
    }),
  },

  watch: {
    selectedSpaceTypes() {
      this.internalSelectedSpaceTypes = this.selectedSpaceTypes;
    },
  },

  methods: {
    onInputChange(){
      this.$emit('spaceTypeUpdate', this.internalSelectedSpaceTypes);
    }
  },
};
</script>

<style lang="scss"></style>
