<template>
  <div class="section-content">
    <div class="primary-section-content">
      <div class="main-wraper-container">
        <div
          class="section-content pt-4"
          :class="{
            'mt-10': $vuetify.breakpoint.xs,
            'mt-n3': $vuetify.breakpoint.sm,
          }"
        >
          <div class="pr-3">
            <insurance-settings-tab v-if="selectedMenu === INSURANCE.Settings" :level="level" :facilityID="facilityID" :isOPS="isOPS" />
            <insurance-library-tab v-if="selectedMenu === INSURANCE.TYPES.LIBRARY.LABEL" :level="level" :facilityID="facilityID" :isOPS="isOPS" />
          </div>
        </div>

        <sidebar-menu
          v-if="toggleStatus"
          :activeIndex="selectedIndex"
          :menuItems="menuTypes"
          v-model="showAside"
          @openView="openTheView"
        ></sidebar-menu>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import InsuranceLibraryTab from './InsuranceLibraryTab.vue';
import InsuranceSettingsTab from './InsuranceSettingsTab/InsuranceSettingsTab.vue';
import SidebarMenu from "./Common/SidebarMenu.vue";
import INSURANCE from '@/constants/insurance.js';
import { EventBus } from './../../../src/EventBus.js';
import { mapGetters } from "vuex";
import SETTING_ENUMS from '@/constants/setting.js';

export default {
  name: "InsuranceSettings",
  props: {
    level: {
      type: String,
      default: ''
    },
    facilityID: {
      type: String,
      default: ''
    },
    isOPS: {
      type: Boolean,
      default: false
    },
    toggleStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      property_id: '',
      showAside: true,
      showDrawer: false,
      showSidebar: true,
      selectedIndex: 0,
      selectedMenu: null,
      menuTypes: [{ name: INSURANCE.TYPES.LIBRARY.LABEL }]
    }
  },
  components: {
    InsuranceLibraryTab,
    InsuranceSettingsTab,
    SidebarMenu
  },
  created() {
    this.INSURANCE = INSURANCE;
    this.SETTING_ENUMS = SETTING_ENUMS;
    EventBus.$on('showHideSidebar', this.showHideSidebar);
    EventBus.$on('openStageDrawer', this.handleOpenStageDrawer);
  },
  mounted() {
    this.showInsuranceSettings();
    this.selectedMenu = this.menuTypes[0].name;
  },
  computed: {
    ...mapGetters({
      companySettings: 'authenticationStore/getSettings',
    }),
  },
  methods: {
    async showHideSidebar(val) {
      this.showAside = (val === true) ? true : false;
    },
    async openTheView(page) {
      this.selectedMenu = page.name;
    },
    showInsuranceSettings() {
      const shouldShowInsuranceSettings = (!!+this.companySettings[this.SETTING_ENUMS.ALLOW_FREE_INSURANCE]) || (!!+this.companySettings[this.SETTING_ENUMS.ALLOW_TABLE_RATE_COVERAGE]);
      if(shouldShowInsuranceSettings) {
        this.menuTypes.unshift({ name: INSURANCE.Settings });
      }
    },
  },
  destroyed() {
    EventBus.$off('showHideSidebar', this.showHideSidebar);
    EventBus.$off('openStageDrawer');
  },

}
</script>

<style lang="scss" scoped>
.section-content {
  padding: 0px !important;
  .main-wraper-container {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
    background-color: #f9fafb !important;
    border-left: 4px solid #f9fafb;
  }

  .v-navigation-drawer__content a.v-list-item--active {
    background-color: #e2f2f3 !important;
    border-left: 4px solid #00848e;
  }
}
</style>