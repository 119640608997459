<template>
  <div>

    <hb-form
      label="API Key"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="client_id"
        name="client_id"
        placeholder="Enter API Key"
        v-validate="'required'"
        v-model="connection.Credentials.api_key"
        data-vv-as="API Key"
        data-vv-scope="creds"
        data-vv-name="client_id"
        :error="errors.collect('creds.client_id').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.api_key}}</span>
    </hb-form>

    <hb-form
      label="Location ID"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="location_id"
        name="location_id"
        placeholder="Enter Location ID"
        v-validate="'required'"
        v-model="connection.Credentials.site_id"
        data-vv-as="Location ID"
        data-vv-scope="creds"
        :error="errors.collect('creds.location_id').length > 0"
      />
      <span v-if="connection.Credentials.connected">***********</span>
    </hb-form>

    <hb-form label="Pin Range" required full :auto-layout="false">
      <v-row no-gutters>
          <div class="hb-inline-sentence-text">
              Minimum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_min}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_min"
              :items="mins"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_min"
              data-vv-as="Minimum Pin"
              :error="errors.has('gate.pin_min')"
          />
          
          <div class="hb-inline-sentence-text">
              Maximum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_max}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_max"
              :items="maxs"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_max"
              data-vv-as="Maximum Pin"
              :error="errors.has('gate.pin_max')"
          />
      </v-row>
    </hb-form>

    <hb-form
      v-if="showPinLenght"
      label="Pin Length"
      required
    >
      <HbSelect
        v-if="!connection.Credentials.connected"
        :items="pin_formats"
        id="pin_format"
        name="pin_format"
        placeholder="Select Pin Length"
        v-validate="'required'"
        v-model="connection.Credentials.pin_format"
        data-vv-as="Pin Length"
        data-vv-scope="gate"
        data-vv-name="pin_format"
        :clearable="false"
        :error="errors.collect('gate.pin_format').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_format}}</span>
    </hb-form>

    <!-- <hb-bottom-action-bar
      :cancel-off="connection.Credentials.connected"
      @close="$emit('cancel')"
      :top-border="false"
    >
      <template v-slot:right-actions>
        <hb-btn
          v-if="!connection.Credentials.connected"
          :disabled="!property_id || savingInProgress"
          :loading="savingInProgress"
          @click="testConnection"
        >
          Test Connection
        </hb-btn>
          <hb-btn
            v-if="!connection.Credentials.connected"
            :disabled="!property_id || savingInProgress"
            :loading="savingInProgress"
            @click="save"
          >
            Connect to Onity
          </hb-btn>

          <hb-btn
            v-if="connection.Credentials.connected"
            color="secondary"
            :disabled="!connection.access_id || removeInProgress"
            :loading="removeInProgress"
            @click="$emit('confirmRemoveAccess')"
          >
            <span class="hb-text-error">Remove Integration</span>
          </hb-btn>
      </template>
    </hb-bottom-action-bar> -->

  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
  name: "Onity",
  mixins: [ notificationMixin ],
  data() {
    return {
      connection: {
        property_id: "",
        access_id: "",
        Credentials: {
          api_key: "",
          site_id: "",
          connected: false
        }
      },
      pin_formats: [],
      mins: ["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      maxs: [],
      showPinLenght: false,
      savingInProgress: false,
    };
  },
  created() {
    this.$emit('connectButtonText', "Connect To Onity");
  },
  mounted() {
    if(this.$props.access.Credentials) {
      this.connection = this.$props.access;
    }
    this.$emit("enableTestConnection", true);
  },
  destroyed() {
    this.$validator.errors.clear();
    this.$emit("enableTestConnection", false);
  },
  methods: {
    async save() {
      let status = await this.$validator.validate();

      let connected = await this.testConnection();
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      if(!connected){
        this.showMessageNotification({ type: 'error', description: 'Error connecting to Onity. Verify your credentials and try again.', list: this.errors.items });
        return;
      }

      this.savingInProgress = true;

      var data = {
        property_id: this.$props.property_id,
        access_id: this.$props.selectedGateAccessId,
        satellite_flag: this.connection.satellite_flag,
        Credentials: {
          api_key: this.connection.Credentials.api_key,
          site_id: this.connection.Credentials.site_id,
          pin_format: this.connection.Credentials.pin_format,
          pin_min: this.connection.Credentials.pin_min,
          pin_max: this.connection.Credentials.pin_max,
        }
      };

      try {

        let r = await api.post(this, api.ACCESS_CONTROL, data);
        this.fetchData();
        this.showMessageNotification({ type: 'success', description: 'You have successfully connected to the Onity access control integration.' });
      } catch( err ) {
        this.showMessageNotification({ description: 'Please verify your credentials and try again or contact Customer Success for assistance.' });
      }


      this.savingInProgress = false;
    },
    async testConnection(){
      let connected = false;
      let gate_status = await this.$validator.validateAll("creds");
      
      if (!gate_status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      this.savingInProgress = true;

      let data = {
          property_id: this.$props.property_id,
          access_id: this.$props.selectedGateAccessId,
          Credentials: {
            api_key: this.connection.Credentials.api_key,
            site_id: this.connection.Credentials.site_id
          }
        }

      try {

        let r = await api.put(this, api.ACCESS_CONTROL + "facilities/" + this.property_id + "/testConnection", data);
        console.log("TestConnectionResult: ", JSON.parse(JSON.stringify(r)))
        
        if(r?.connected){
          connected = true;
          this.showMessageNotification({ type: 'success', description: 'Connection successfull.' });
        } else {
          this.showMessageNotification({ description: 'Error connecting to Onity. Verify your credentials and try again.' });
        }

      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }

      this.savingInProgress = false;
      return connected;

    },
    fetchData() {
      EventBus.$emit('fetchSettingsAccess');
    },
    populateArray(start, end) {
      const arr = [];
      start = parseInt(start);
      end = parseInt(end);
      for (let i = start; i <= end; i++) {
        arr.push(i.toString());
      }

      return arr;
    }
  },
  watch: {
    access() {
      this.connection = this.access;
    },
    'connection.Credentials.pin_min'() {
      this.maxs = this.populateArray(this.connection.Credentials.pin_min, 12)
    },
    'connection.Credentials.pin_max'() {
      this.showPinLenght = true;
      this.pin_formats = this.populateArray(this.connection.Credentials.pin_min, this.connection.Credentials.pin_max)
    },
    savingInProgress(value) {
      this.$emit('update:savingAccess', value);
    },
    async saveInVendor(value) {
      if (value === true) {
        await this.save();
        this.$emit('update:saveInVendor', false);
      }
    },
    async testVendorConnection(value) {
      if (value === true) {
        await this.testConnection();
        this.$emit('update:testVendorConnection', false);
      }
    }
  },
  props: ["property_id", "access", "selectedGateAccessId", "removeInProgress", "showUpdateButton", "savingAccess", "saveInVendor", "updateInVendor", "testVendorConnection"]
};
</script>

<style lang="scss" scoped>
</style>
