<template>
  <div>

    <v-expansion-panels v-if="property_id" v-model="panel" multiple>
      <hb-expansion-panel v-for="(accessControlData, key) in accessControlList" :key="key">
        <template v-slot:title>
            Access Control System{{(key + 1) === 1 ? '' : ' ' + (key + 1)}}
        </template>
        <template v-slot:content>
          <access-control-system
            :disableCancel="key === 0"
            :syncReportFlag.sync="syncReport" 
            :showAccessHoursSettings="showAccessHoursSettings" 
            :property_id="property_id"
            :accessControlData="accessControlData"
            :integrationTypes="integrationTypes"
            :fetchingAccessControl="fetchingAccess"
            @cancelNewAccess="cancelNewAccess"
          />
        </template>
      </hb-expansion-panel>
      <v-row>
        <v-col>
          <hb-btn
            v-if="showAddAccess && showAccessHoursSettings"
            :disabled="fetchingAccessControl"
            @click="addSatelliteAccess"
            color="secondary"
            prepend-icon="mdi-plus"
          >
          Add Access Control System
          </hb-btn>
        </v-col>
      </v-row>

    </v-expansion-panels>
    
  </div>
</template>

<script type="text/babel">

import AccessControlSystem from "./AccessControlSystem.vue";
import { EventBus } from "../../../EventBus.js";
import api from "../../../assets/api";

export default {
  name: "AccessControlConfiguration",
  components: { AccessControlSystem },
  props: ["showAccessHoursSettings", "property_id", "syncReportFlag", "fetchingAccessControl"],
  data() {
    return {
      panel: [0],
      integrationTypes: [],
      unconnectedAccessControl: {
          access: {},
          agentInfo: {},
      },
      unconnectedSatelliteAccess: {
          access: { satellite_flag: 1, Credentials: {} },
          agentInfo: {},
      },
      accessControlList: [],
      propertyAccessAreas: [],
      propertyAccessLevels: {},
      syncReport: false,
      fetchingAccess: false,
    };
  },
  computed: {
    showAddAccess() {
      let showButton = false;
      let accessCount = this.accessControlList.length
      if (accessCount > 0) {
        let lastAccessEntry = this.accessControlList[accessCount - 1]
        if (lastAccessEntry?.access?.Credentials?.connected === true) showButton = true;
      }
      return showButton;
    }
  },
  methods: {
    deepCopy(object) {
      return JSON.parse(JSON.stringify(object))
    },
    cancelNewAccess() {
      this.accessControlList.pop();
    },
    addSatelliteAccess() {
      this.accessControlList.push(this.deepCopy(this.unconnectedSatelliteAccess));
      let newPanelIndex = this.accessControlList.length - 1;
      let panelOpened = this.panel.find(opened => opened === newPanelIndex);
      if (!panelOpened) this.panel.push(newPanelIndex);
    },
    async fetchGateVendorsList() {
      await api.get(this, api.ACCESS_CONTROL + "vendors").then(r => {
        let vendors = r.vendors;
        vendors.map(gate_type => {
          if(gate_type.name === "Derrels") gate_type.name = "DMS";
          return gate_type;
        });
        this.integrationTypes = vendors;
      });
    },
    async fetchAccessControl() {
      if (this.property_id) {
        try {
          this.fetchingAccess = true;
          if (!this.integrationTypes?.length) await this.fetchGateVendorsList()
          let accessResponse = await api.get(this, api.PROPERTIES + this.property_id + "/access");
          if (accessResponse?.access?.Credentials) {
            // // let accessAreas = await this.fetchAccessAreas()
            // // let accessLevels = await this.fetchAccessLevels()
            // // let vendorAreas = await this.fetchVendorAreas()
            // let accessControlData = {
            //   ...accessResponse,
            //   // accessAreas,
            //   // accessLevels,
            //   // vendorAreas
            // }
            //TODO SET TO ACTUAL LIST
            this.accessControlList = [ accessResponse ];
            
          } else {
            this.accessControlList = [this.deepCopy(this.unconnectedAccessControl)];
          }
          if (accessResponse.satellites?.length) this.accessControlList.push(...accessResponse.satellites);
        } catch (err) {
          this.accessControlList = [this.deepCopy(this.unconnectedAccessControl)]
        } finally {
          this.fetchingAccess = false;
        }
      }
    },
  },
  watch: {
    async property_id(propertyValue) {
      this.accessControlList = [this.deepCopy(this.unconnectedAccessControl)];
      this.fetchAccessControl();
    },
    fetchingAccess(fetchingStatus) {
      this.$emit('update:fetchingAccessControl', fetchingStatus);
    },
    syncReport(flagValue) {
      this.$emit('update:syncReportFlag', flagValue);
    }
  },
  async mounted() {
    EventBus.$on('fetchSettingsAccess', this.fetchAccessControl);
    this.accessControlList = [this.deepCopy(this.unconnectedAccessControl)];
    await this.fetchGateVendorsList();
    await this.fetchAccessControl();
  },
  destroyed(){
    EventBus.$off('fetchSettingsAccess', this.fetchAccessControl);
  },
};
</script>

<style scoped>

</style>
  