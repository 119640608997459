import { render, staticRenderFns } from "./Noke.vue?vue&type=template&id=25c8f1b8&scoped=true&"
import script from "./Noke.vue?vue&type=script&lang=js&"
export * from "./Noke.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c8f1b8",
  null
  
)

export default component.exports