<template>
  <div>
    <div class="hb-cloud-lighter pa-4">
      <div>
        <HbNotification
            v-model="isUpdateAvailable"
            v-if="property_id && isUpdateAvailable"
            type="guidance"
        >
          <span class="font-weight-medium">Exciting News:</span> Agent App Update Now Available! Click Download and use <span class="font-weight-medium">Code: {{install_code}} </span> to install latest version.
          <template v-slot:condensedActions>
            <hb-menu
                options
                options-hover-type="guidance"
            >
              <v-list>
                <v-list-item :ripple="false" @click="downloadAgentUpdate(install_code)">
                  <v-list-item-title>Download</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>

          <template v-slot:actions>
            <div class="d-flex align-center">
              <div class="mr-2 pt-2" v-if="current_version">
                Current version: <span class="font-weight-medium">V{{current_version}}</span>
              </div>

              <hb-btn small color="secondary" @click="downloadAgentUpdate(install_code)">Download</hb-btn>
            </div>
          </template>
        </HbNotification>
        <v-expansion-panels v-if="property_id" v-model="panel" multiple>
          <hb-expansion-panel :top-margin="isUpdateAvailable">
            <template v-slot:title>
              {{accessSettingsTitle}}
            </template>
            <template v-slot:content>
              <hb-form label="Integration Type">
                <HbSelect
                    :items="integrationTypes"
                    :disabled="disableGateSelection || fetchingAccessControl"
                    item-text="name"
                    return-object
                    :id="'integration_type'"
                    :name="'integration_type'"
                    placeholder="Select"
                    v-validate="'required|max:45'"
                    v-model.lazy="selectedIntegration"
                    data-vv-scope="gate"
                    data-vv-as="Integration Type"
                    :error="errors.collect('gate.integration_type').length > 0"
                    @change="validateFacilitySelected()"
                />
              </hb-form>
              <component 
                :is="selectedVendorComponent"
                :access="access"
                :selectedGateAccessId="selectedGateAccessId"
                :property_id="property_id"
                :showUpdateButton.sync="showUpdateButton"
                :saveInVendor.sync="saveInVendor"
                :savingAccess.sync="savingAccess"
                :updateInVendor.sync="updateInVendor"
                :testVendorConnection.sync="testConnection"
                :accessFacilityId="accessFacilityId"
                @confirmFacilitySync="confirmFacilitySync = true"
                @connectButtonText="setConnectText"
                @enableTestConnection="enableTestConnection"
              />
            </template>
          </hb-expansion-panel>

          <hb-expansion-panel
              v-if="accessIsConnected && (selectedAccessType === 'pti' || selectedAccessType === 'rcs') && showAccessHoursSettings"
          >
            <template v-slot:title>
              Access Hours
            </template>
            <template v-slot:content>
              <hb-form
                  label="Setup Hours"
                  @cancel="cancelAccessLevel"
                  @save="saveAccessLevels"
                  @editStart="editAccessLevels"
                  :empty="accessLevels.length == 0"
                  :editable="!changeLevelInProgress && !fetchInfoInProgress"
                  after-clickable
                  ref="accessLevelComponent"
                  :active.sync="active_levels"
                  description="Enter your Access Hour information exactly as it appears on your Access Control System"
              >
                <template v-slot:display>
                  <p v-for="(al, i) in accessLevels" :key="i">
                    <span class="font-weight-medium">{{al.external_id_number}}</span> - {{ al.name }}
                  </p>
                </template>
                <template v-slot:edit>
                  <v-row v-for="(level, index) in accessLevels" :key="index" class="mt-2" no gutters>
                    <v-col cols="5">
                      <HbTextField
                          v-model="level.external_id_number"
                          v-validate='{required, numeric, max:45, unique_external_id:accessLevels}'
                          :data-vv-scope="'level'"
                          :data-vv-name="'id_' + index"
                          :data-vv-as="'Access Hour ID' + (level.external_id_number ? ' ' + level.external_id_number : '')"
                          :error="displayErrors['id_' + index]"
                          :disabled="level.locked"
                          placeholder="Access Hour ID"
                          full
                      ></HbTextField>
                    </v-col>
                    <v-col cols="5">
                      <HbTextField
                          v-model="level.name"
                          v-validate='{required, max:45, unique_hour_name:accessLevels}'
                          :data-vv-scope="'level'"
                          :data-vv-name="'name_' + index"
                          :data-vv-as="'Access Hour Name' + (level.name ? ' ' + level.name : '')"
                          :error="displayErrors['name_' + index]"
                          placeholder="Access Hour Name"
                          full
                      ></HbTextField>
                    </v-col>
                    <v-col cols="auto" style="max-width: 12px;">
                      <HbBtn icon tooltip="Clear" mdi-code="mdi-close-circle" small @click="removeLevel(index)"/>
                    </v-col>
                  </v-row>
                  <hb-link @click="addLevel" color="primary">+ Add Access Hours</hb-link>
                </template>
                <template v-slot:after v-if="hour_audit.modified_by_fullname">
                  <v-row>
                    <HbBtn icon class="ml-auto" :tooltip="hour_audit.modified_by_fullname + ' ' + editHourTime" mdi-code="mdi-account-clock-outline" />
                  </v-row>
                </template>
              </hb-form>
            </template>
          </hb-expansion-panel>

          <hb-expansion-panel
              v-show="access && access.Credentials && access.Credentials.connected && showAccessHoursSettings"
          >
            <template v-slot:title>
              Access Areas
            </template>
            <template v-slot:actions>

            </template>
            <template v-slot:content>
              <hb-form
                  class="pb-20"
                  label="Configure Access Area"
                  @cancel="cancelAreaEdit"
                  @save="saveAccessAreas"
                  @editStart="editHasStarted"
                  :active.sync="active"
                  :editable="!changeAreaInProgress && !fetchInfoInProgress"
                  after-clickable
                  multiple
                  extra-large
                  description="Configured list of Access Areas followed by the default Access Hour initially selected during move-ins and transfers.">

                <template v-slot:tooltipBody>
                  To edit an area name or default access hours, simply click on the area name. This will open an editing interface where you can make the necessary changes.
                </template>
                <template v-slot:display>
                  <p v-for="(ar, i) in accessAreas" :key="i">
                    <span class="font-weight-medium">{{ar.name}}</span> - <span v-if="selectedAccessType === 'rcs'"> {{ ar.external_area_id_number }} - </span>{{ ar.default_vendor_access_level_name }}
                  </p>

                </template>
                <template v-slot:edit>
                  <hb-notification
                      v-model="onboardUserAccessHourFlag"
                      v-if="onboardUserAccessHourFlag"
                      :type="onboardUserAccessHourStatus"
                      not-dismissable
                      title-off
                  >
                    {{ onboardUserAccessHourDescription }}
                    <template v-slot:condensedActions>
                      <hb-menu
                          options
                          :options-hover-type="onboardUserAccessHourStatus"
                      >
                        <v-list>
                          <v-list-item :ripple="false" @click="onboardUserAccessHour = false">
                            <v-list-item-title>{{onboardUserAccessHourStatus === 'guidance' ? "Onboard" : "Retry Onboard"}}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </hb-menu>
                    </template>
                    <template
                        v-if="onboardUserAccessHourStatus !== 'success'"
                        v-slot:actions>
                      <hb-btn small color="secondary" :loading="onboardUserAccessHourInProgress" @click="onboardUserAccessHour">{{onboardUserAccessHourStatus === 'error' ? "Retry Onboard" : "Onboard"}}</hb-btn>
                    </template>
                  </hb-notification>
                  <v-row v-for="(area, areaIndex) in accessAreas" :key="areaIndex" class="mt-2" no gutters>
                    <v-col cols="4">
                      <HbTextField
                          v-model="area.name"
                          v-validate='{ required, max:45, unique_hb_area:accessAreas }'
                          :data-vv-scope="'area'"
                          :data-vv-name="'hb_area_' + areaIndex"
                          :data-vv-as="'Access Area ' + (areaIndex + 1)"
                          :error="errors.has('area.hb_area_' + areaIndex)"
                          placeholder="Enter Access Area Name"
                          full
                      ></HbTextField>
                    </v-col>
                    <v-col cols="3" v-if="selectedAccessType === 'open_tech_cia' || selectedAccessType === 'spiderdoor'">
                      <HbSelect
                          :items="vendorAreas"
                          :disabled="disable"
                          item-text="name"
                          item-value="id"
                          :id="'vendorZone'"
                          :name="'vendor_zone_' + areaIndex"
                          :placeholder="vendorAreasPlaceholder"
                          v-model.lazy="area.vendor_access_area_id"
                          :data-vv-scope="'area'"
                          :data-vv-as="'Vendor Zone ' + (areaIndex + 1) "
                          :error="errors.collect('area.vendor_zone_' + areaIndex).length > 0"
                          full
                      />
                    </v-col>
                    <v-col cols="3" v-if="selectedAccessType === 'rcs'">
                      <HbTextField
                          v-model="area.external_area_id_number"
                          v-validate='{required, max:99, unique_keypad_id:accessAreas}'
                          :data-vv-scope="'area'"
                          :data-vv-name="'hb_keypad_area_' + areaIndex"
                          :data-vv-as="'Keypad Area ' + (areaIndex + 1)"
                          :error="errors.has('area.external_area_id_number' + areaIndex)"
                          placeholder="Enter Keypad Area ID"
                      />

                      </v-col>
                    <v-col cols="4">
                      <HbSelect
                          :items="accessLevels"
                          :disabled="disable"
                          item-text="name"
                          item-value="id"
                          :id="'accessHour'"
                          :name="'access_hour_' + areaIndex"
                          placeholder="Select Access Hour"
                          v-model.lazy="area.default_vendor_access_level_id"
                          :data-vv-scope="'area'"
                          :data-vv-as="'Access Hour ' + (areaIndex + 1)"
                          :error="errors.collect('area.access_hour_' + areaIndex).length > 0"
                          full
                      />

                    </v-col>
                    <v-col cols="auto" style="max-width: 12px;">
                      <HbBtn icon tooltip="Clear" mdi-code="mdi-close-circle" small @click="removeArea(areaIndex)"/>
                    </v-col>
                  </v-row>
                  <hb-link @click="addArea" color="primary">+ Add Access Area</hb-link>
                </template>
                <template v-slot:after v-if="area_audit.modified_by_fullname">
                  <v-row>
                    <HbBtn icon class="ml-auto" :tooltip="area_audit.modified_by_fullname + ' ' + infoEditTime" mdi-code="mdi-account-clock-outline" />
                  </v-row>
                </template>
              </hb-form>
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>


      </div>
    </div>
    <v-divider></v-divider>
    <hb-bottom-action-bar
      :cancel-off="accessIsConnected || disableCancel"
      @close="cancel"
      :top-border="false"
      v-resize="setAuditCollapse"
      ref="bottomRemoveBar"
      > 
      <template v-slot:left-actions v-if="accessHasModified">
        <hb-tooltip v-if="collapseAudit">
          <template v-slot:item>
            <v-row no-gutters align="center">
              <hb-icon 
                mdi-code="mdi-account-clock-outline"
                small
                color="#101318"  
                class="mr-2 ml-4 mt-0 mb-1"
              >
              </hb-icon>
            </v-row>
          </template>
          <template v-slot:body>
            {{"Connected by " + access.modified_by_fullname}}<br>{{accessEditTime}}
          </template>
        </hb-tooltip>
        <v-row no-gutters align="center" v-else>
          <hb-icon 
            mdi-code="mdi-account-clock-outline"
            small
            color="#101318" 
            class="mr-2 ml-4 mt-0 mb-1"
          >
          </hb-icon>
          <v-col class="py-0 my-0 hb-font-caption">
            {{"Connected by " + access.modified_by_fullname + " (" + accessEditTime + ")"}}
          </v-col>
        </v-row>
      </template>

      <template v-slot:right-actions>
        <div v-if="accessIsConnected ">
          <hb-btn
            v-if="showUpdateButton"
            :disabled="savingAccess"
            :loading="savingAccess"
            @click="updateInVendor = true"
          >
            Update Integration
          </hb-btn>

          <hb-btn
            color="secondary"
            :disabled="removeInProgress"
            :loading="removeInProgress"
            @click="confirmRemoveModal = true"
          >
            <span class="hb-text-error">Remove Integration</span>
          </hb-btn>
        </div>
        <div v-if="!accessIsConnected && selectedAccessType">
          <hb-btn
            v-if="testConnectionEnabled"
            :disabled="savingAccess"
            :loading="savingAccess"
            @click="testConnection = true"
          >
            Test Connection
          </hb-btn>

          <hb-btn
            :disabled="savingAccess"
            :loading="savingAccess"
            @click="saveInVendor = true"
          >
            {{connectButtonText}}
          </hb-btn>
        </div>

      </template>
    </hb-bottom-action-bar>

    <hb-modal
        v-model="confirmRemoveModal"
        title="Remove Access Control"
        size="medium"
        confirmation
        show-help-link
    >
      <template v-slot:content>
        <div class="pa-4 hb-font-body">
          If you are changing your access control system, please contact <span class="hb-font-body-medium">support@tenantinc.com</span> to ensure your data is synchronized.
          <br /><br />
          Removing your access control integration in Hummingbird will disconnect communication with your access control system.
          <br /><br />
          Are you sure you want to continue?
        </div>
      </template>

      <template v-slot:right-actions>
        <hb-btn
            color="destructive"
            @click="removeAccess"
            :disabled="removeInProgress"
            :loading="removeInProgress"
        >
          Remove
        </hb-btn>
      </template>
    </hb-modal>

    <hb-modal
        v-model="confirmFacilitySync"
        title="Sync all Tenants to Gate Vendor"
        size="medium"
        confirmation
        show-help-link
    >
      <template v-slot:content>
        <div class="pa-4">
          You are about to synchronize Hummingbird with the selected Access control system. This will replace the existing Access Control System data with the data from Hummingbird.
          <br /><br />
          Are you sure you want to proceed?
        </div>
      </template>

      <template v-slot:right-actions>
        <hb-btn
            color="primary"
            @click="facilitySync"
        >
          Confirm
        </hb-btn>
      </template>
    </hb-modal>

    <hb-modal
        title="Confirm Access Hour Changes"
        v-model="confirmLevelVisible"
        size="medium"
        content-padding
        confirmation
    >
      <template v-slot:content>
        <div class="hb-text-light hb-font-body">{{ accessLevels.length > 0 ? 'You are modifying the Access Hours for this property' : 'You are adding Access Hours for this property' }}</div>
        <div class="hb-text-light hb-font-body-medium">
          <br />
          Use the same ID and Name as your Access Control System
          <br />
          to avoid disrupting tenant access
          <br /><br />
        </div>
        <div class="hb-text-light hb-font-body">Are you sure you want to continue?</div>
      </template>
      <template v-slot:right-actions>
        <hb-btn
            color="primary"
            @click="confirmAccessLevelChanges"
            :disabled="changeLevelInProgress"
            :loading="changeLevelInProgress"
        >
          Proceed
        </hb-btn>
      </template>
    </hb-modal>

    <hb-modal
        v-model="dialogModal"
        size="medium"
        title="Upload Files"
        @close="close()"
        show-help-link
    >
      <template v-slot:content>
        <div class="px-6 font-style">
          <div v-if="error" style="color:red;">
            Please upload files of type .csv .xls or .xlsx
          </div>
          <v-file-input
              accept=".csv, .xls, .xlsx"
              v-model="files"
              placeholder="Select File"
              :rules="[rules.size]"
              @change="isCorrectFile()"
              prepend-icon="mdi-paperclip"
          ></v-file-input>

        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" :disabled="isDisable()" @click="upload()"
        >Upload</hb-btn
        >
      </template>
    </hb-modal>

  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Loader from '../../assets/CircleSpinner.vue';
import Status from '../../includes/Messages.vue';
import Brivo from "./Brivo.vue";
import OpenTech from "./OpenTechCIA.vue";
import Noke from "./Noke.vue";
import PTI from "./PTI.vue";
import PtiCloud from "./PtiCloud.vue";
import Sentinel from "./Sentinel.vue";
import Default from "./Default.vue";
import DoorKing from "./DoorKing.vue";
import PDK from "./PDK.vue";
import SpiderDoor from "./SpiderDoor.vue";
import DMS from "./DMS.vue";
import Onity from "./Onity.vue"
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import { EventBus } from "../../../EventBus.js";
import * as XLSX from 'xlsx';
export default {
  name: "AccessControlSystem",
  mixins: [notificationMixin],
  components: { Loader, Status, Brivo, OpenTech, Noke, PTI,PtiCloud, Sentinel, Default, DoorKing, PDK, SpiderDoor, DMS, Onity },
  props: ["facilityID", "showAccessHoursSettings", "property_id", "syncReportFlag", "accessControlData", "integrationTypes", "fetchingAccessControl", "disableCancel"],
  data() {
    return {
      connectButtonText: "Connect",
      savingAccess: false,
      displayErrors: {},
      open_sections: ["gate"],
      access: {
        property_id: "",
        modified_by_fullname: "",
        modified_at: "",
        Credentials: {}
      },
      selectedIntegration: null,
      disableGateSelection: false,
      panel: [0],
      removeInProgress: false,
      confirmRemoveModal: false,
      confirmFacilitySync: false,
      selectedDefaultScheduleItem: {},
      defaultScheduleModal: false,
      agentUpdateModal: false,
      downloadAgentInProgress: false,
      saveDefaultScheduleInProgress: false,
      install_code: "",
      current_version: "",
      latest_version: "",
      agent_type_id: null,
      gate_facility_id: null,
      hasNewVersion: false,
      accessArea: [],
      isUpdateAvailable: false,
      accessAreas: [],
      accessLevels: [],
      vendorAreas: [],
      disable: false,
      active: '',
      active_levels: '',
      editAccess: false,
      savedAccessAreas: [],
      confirmLevelVisible: false,
      fetchInfoInProgress: false,
      changeLevelInProgress: false,
      changeAreaInProgress: false,
      tempAccessLevels: [],
      duplicate_error_rules: [],
      editing: false,
      onboardUserAccessHourFlag: false,
      onboardUserAccessHourStatus: null,
      onboardUserAccessHourDescription: '',
      onboardUserAccessHourInProgress: false,
      accessHourSyncStatus: 0,
      dialogModal: false,
      files: undefined,
      error: false,
      rules: {
        size: value =>
            !value ||
            value.size < 5242880 ||
            "The file size should not exceed 5MB!"
      },
      area_audit: {
        modified_by_fullname: '',
        modified_at: null
      },
      hour_audit: {
        modified_by_fullname: '',
        modified_at: null
      },
      collapseAudit: false,
      //data to run vendor functions
      testConnection: false,
      updateInVendor: false,
      saveInVendor: false,
      //data to toggle button visibility in main component
      showUpdateButton: false,
      testConnectionEnabled: false,
    };
  },
  computed: {
    accessFacilityId() {
      return this.access?.facility_id;
    },
    accessSettingsTitle() {
      if (this.access?.Credentials?.connected && this.selectedIntegration?.name) {
        return this.selectedIntegration.name;
      }
      return "Access Control Settings"

    },
    accessIsConnected() {
      if (this.access?.Credentials?.connected) return true;
      return false;
    },
    accessHasModified() {
      return this.access?.modified_at && this.access?.modified_by_fullname
    },
    accessEditTime(){
        if(this.access.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.access.modified_at);
        return '';
     },
    infoEditTime(){
      if(this.area_audit.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.area_audit.modified_at);
      if(this.hour_audit.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.hour_audit.modified_at);
      return '';
    },
    editHourTime(){
      if(this.hour_audit.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.hour_audit.modified_at);
      return '';
    },
    selectedAccessType() {
      return this.selectedIntegration?.label
    },
    selectedGateAccessId() {
      return this.selectedIntegration?.id
    },
    selectedVendorComponent() {
      let vendorType = this.selectedAccessType
      switch(vendorType) {
        case 'derrels': return DMS;
        case 'brivo': return Brivo;
        case 'open_tech_cia': return OpenTech;
        case 'noke': return Noke;
        case 'pti': return PTI;
        case 'pti_cloud': return PtiCloud;
        case 'sentinel': return Sentinel;
        case 'rcs': return Default;
        case 'default': return Default;
        case 'doorking': return DoorKing;
        case 'pdk': return PDK;
        case 'spiderdoor': return SpiderDoor;
        case 'onity': return Onity;
        default: return null;
      }
    },
    vendorAreasPlaceholder(){
      let select = "Select ";
      switch (this.selectedAccessType) {
        case "open_tech_cia": return select + "Access Profile";
        case "spiderdoor": return select + "Keypad Zone";
        case "rcs": return select + "Keypad";
        default: return select + "Vendor Zone";
      }
    },
    sendExternalIdNumber() {
      return this.selectedAccessType === 'rcs';
    }
  },
  methods: {
    setConnectText(connectButtonText) {
      this.connectButtonText = connectButtonText;
    },
    enableTestConnection(enabled) {
      this.testConnectionEnabled = enabled;
    },
    setAuditCollapse() {
      let bottomBar = this.$refs.bottomRemoveBar;
      if (bottomBar && bottomBar.$el.offsetWidth > 0 && bottomBar.$el.offsetWidth < 570) {
        this.collapseAudit = true
      } else {
        this.collapseAudit = false
      }
    },
    // gateSelection(val) {
    //   this.disableGateSelection = val;
    //   // this.$emit('setAccessType', this.selectedAccessType);
    //   // this.fetchData();
    //   // this.fetchVendorAreasPlaceholder();
    // },
    async validateFacilitySelected() {
      let gate_status = await this.$validator.validate("gate.facility");
      if (!gate_status) {
        // this.selectedAccessType = null;
        this.selectedIntegration = null;
        var collectables = this.errors.items.filter(obj => obj.rule === "unique_external_id");
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items[0] });
        return;
      }
    },
    cancel() {
      this.$emit('cancelNewAccess');
      // this.access = {}
      // this.selectedIntegration = null;
      // // this.selectedAccessType = null;
      // this.disableGateSelection = false;

    },
    isOpen(section) {
      return this.open_sections.indexOf(section) >= 0;
    },
    toggleSection(section) {
      if (this.open_sections.indexOf(section) >= 0) {
        this.open_sections.splice(this.open_sections.indexOf(section), 1);
      } else {
        this.open_sections.push(section);
      }
    },
    async addArea() {
      this.accessAreas.push({ name: '', vendor_access_area_name: null, default_vendor_access_level_name: null });
    },

    async addLevel() {
      this.accessLevels.push({ id: null, name: '' });
    },
    removeArea(index) {
      this.accessAreas.splice(index, 1);
    },
    removeLevel(index) {
      this.accessLevels.splice(index, 1);
    },
    async fetchAccessInfo(){
      this.fetchInfoInProgress = true;
      try {
        await this.fetchAccessAreas();
        await this.fetchAccessLevels();
        await this.fetchVendorAreas();
        this.fetchInfoInProgress = false;
      } catch {
        this.fetchInfoInProgress = false;
      }
    },
    async fetchAccessLevels() {
      this.accessLevels = [];
      let levelsResponseArray = await api.get(this, api.PROPERTIES + this.property_id + "/access-levels?active=1&facility_id=" + this.accessFacilityId);
      if (levelsResponseArray.length) {
        this.accessLevels = levelsResponseArray;
        // for (const level of levelsResponseArray) {
        //   if (level.facility_id === this.accessFacilityId) {
        //     this.accessLevels.push(level);
        //   }
        // }

      }
      this.hour_audit.modified_by_fullname = this.accessLevels[0]?.modified_by_fullname;
      this.hour_audit.modified_at = this.accessLevels[0]?.modified_at;
    },
    async fetchVendorAreas() {
      this.vendorAreas = [];
      let r = await api.get(this, api.ACCESS_CONTROL + "facilities/" + this.property_id + "/gate-vendor-information?active=1");
      let vendor_zones = r?.property_vendor_information?.vendor_access_areas;
      if (vendor_zones?.length) this.vendorAreas = vendor_zones;
    },
    async fetchAccessAreas() {
      this.accessAreas = [];
      let areasResponseArray = await api.get(this, api.PROPERTIES + this.property_id + "/areas?active=1");
      if (areasResponseArray.length) {
        // this.accessAreas = areasResponseArray;
        for (const area of areasResponseArray) {
          if (area.facility_id === this.accessFacilityId) {
            this.accessAreas.push(area);
          }
        }
      }
      this.area_audit.modified_by_fullname = this.accessAreas[0]?.modified_by_fullname;
      this.area_audit.modified_at = this.accessAreas[0]?.modified_at;
    },
    checkOnboardingAccessHoursStatus(){
      const checkStatus = async () => {
        await this.getOnboardingUserAccessHourStatus();
        if (this.accessHourSyncStatus === 1) {
          console.log("Onboarding is still in progress. Checking again...");
          setTimeout(checkStatus, 5000); // Wait for 5 seconds before checking again
        } else {
          console.log("Onboarding status:", this.accessHourSyncStatus);
          this.onboardUserAccessHourInProgress = false
        }
      };

      checkStatus();
    },
    async onboardUserAccessHour(){
      if(this.selectedAccessType === 'pti' || this.selectedAccessType === 'rcs'){
        this.dialogModal = true;
      }else if(this.selectedAccessType === 'pti_cloud' || this.selectedAccessType === 'open_tech_cia' || this.selectedAccessType === 'doorking' || this.selectedAccessType === 'spiderdoor'){
        this.onboardUserAccessHourInProgress = true;
        this.onboardUserAccessHourStatus = "guidance"
        this.onboardUserAccessHourDescription = "Access Hours onboarding in progress ...."
        await api.put(this, api.PROPERTIES + this.property_id + "/facilities/" + this.accessFacilityId + "/onboard-user-accesslevels").then( r => {
          console.log("ONBOARDING RESPONSE: ", r)
        }).catch(error => {
          console.log("Error: ", error)
          this.onboardUserAccessHourInProgress = false;
          this.onboardUserAccessHourStatus = "error"
          this.onboardUserAccessHourDescription = "An error has occured. Please onboard again."
          this.showMessageNotification({ type: 'error', description: error});
        });
        await this.checkOnboardingAccessHoursStatus();
      }else{
        this.showMessageNotification({ type: 'error', description: 'Onboarding feature not available for this vendor at the moment'});
      }
    },
    async getOnboardingUserAccessHourStatus(){

      await api.get(this, api.PROPERTIES + this.property_id + "/facilities/" + this.accessFacilityId + "/onboard-user-accesslevels-status").then(r => {
        this.accessHourSyncStatus = r
        console.log("Access Hour Sync Status: ", this.accessHourSyncStatus)
        if(r){
          if(r === 2){
            //success
            this.onboardUserAccessHourStatus = "success"
            this.onboardUserAccessHourDescription = "Access hours was onboarded successfully."
          }else if (r === 1){
            //In Progress
            this.onboardUserAccessHourInProgress = true;
            this.onboardUserAccessHourStatus = "guidance"
            this.onboardUserAccessHourDescription = "Access Hours onboarding in progress ...."
          } else {
            //ERROR
            this.onboardUserAccessHourStatus = "error"
            this.onboardUserAccessHourDescription = "An error has occured. Please onboard again."
          }
        }
      }).catch(error => {
        console.log("Error Getting onboarding User Access Hour Status: ", error)
        //this.showMessageNotification({ type: 'error', description: 'Unable to get onboarding status'});
      });
    },
    compareVersions(currentVersion, latestVersion) {
      if(currentVersion === null) return -1;

      const currentComponents = currentVersion.split('.').map(Number);
      const latestComponents = latestVersion.split('.').map(Number);

      for (let i = 0; i < Math.max(currentComponents.length, latestComponents.length); i++) {
        const current = currentComponents[i] || 0;
        const latest = latestComponents[i] || 0;

        if (current < latest) {
          return -1; // Current version is older
        } else if (current > latest) {
          return 1; // Current version is newer
        }
      }

      return 0; // Versions are equal
    },
    async removeAccess() {
      this.removeInProgress = true;


      try {
        if (this.access.satellite_flag === 1) {
          for (const area of this.accessAreas) {
            if (area?.id) await api.delete(this, api.PROPERTIES + this.property_id + "/areas/" + area.id)
          }
        }
        await api.delete(this, api.PROPERTIES + this.property_id + "/facilities/" + this.accessFacilityId + "/access", null, "accessRemove");
        // this.cancel();
        this.showMessageNotification({ type: 'success', description: 'You have successfully removed the access control integration.' })
      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }
      this.resetGateUpdateInfo();
      this.confirmRemoveModal = false;
      EventBus.$emit('fetchSettingsAccess');
      this.removeInProgress = false;
    },
    async facilitySync() {
      try {

        let r = await api.put(
            this,
            api.ACCESS_CONTROL + 'properties/' + this.property_id + "/sync",
            null,
            "accessRemove"
        );
        const payload = {
          type: 'gate_access_sync',
          title: 'Syncing Tenants to Access Control System',
          persistent: true,
          loading: true,
          description: 'Please wait while we sync tenant data to the Access Control System.'
        }
        this.confirmFacilitySync = false;
        this.showMessageNotification(payload)

      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }
    },
    openDefaultScheduleModal(item){
      this.selectedDefaultScheduleItem = item;
      this.defaultScheduleModal = true;
    },

    async downloadAgentUpdate(install_code){
      this.downloadAgentInProgress = true;


      try {

        let r = await api.get(
            this,
            api.ACCESS_CONTROL + "facilities/" + this.property_id + "/gate_agent/download"
        );

        if(r){

          const link = document.createElement('a');
          link.href = r.download;
          //link.href = "https://gate-agent-config.s3.us-east-1.amazonaws.com/main_install/main_installer.exe?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFYaCXVzLWVhc3QtMSJIMEYCIQCCUzgonlQs0S0LrJlXkHSAUW6d3HlyegonQE4Ra1oZEgIhAOX%2BLsCpqoJ2r%2FOOJ5Z8RB6FCCNdC6XSknGfwbIqEK%2FVKogDCK%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAxoMMzgwMjQ2OTExMzMwIgxI1nhDt78DSAuJZewq3AKAVqeV4TTrO57sOo%2BfZssCHCxxy91eOimAV0X%2BVzIL%2BRv4uXKLxxf%2FRqRxwiLZR4ajynqwVbAyI%2B8bieru%2FmgNpStIh24AYH2W9ptoRY2i4oecniFnk2eSM5SSO%2FpIRPvyQoC7o9kOPP8uqomCW5Znw0%2BUU3OnIveNRJo5GDUj07mrkNevGrnzIyKkahBEyirq2GswJd%2BDRy3E%2BGeQPvKWRb9f1GAhgCXv8rV0%2BIcgIo2wl5BqEuqL3VAT75vkIAZZH3K46GgPdusTNug7k%2B6m6ZOUfi9iLtMri3FwU9HQ7Tz8D8%2BQHJVXuEH1dFmuk6YED7wxZnJpmZUZL%2F2Hj8g9idjzht6ETyqF8Zvbtg%2FOuEq8aLBOO2kOXi8o3qsfVWC4636hJcbkrxV54iRsT9cNCexdk9sE9z5hESW8x5UBDPD%2BYRYm8bHkNO65bE1OLyCZfpqpsjapZPDVFuswqoPgsQY6sgJV18VVgJj5KfC2Ngek7Y9DuneeTX%2FybrJlJTarKNr6M9Z9j9PlCl5GsGpqCLkdWt6ymchVJye2eGT1VfyDiop1JC%2FKHLKZBPJvswoZ9CwSFlv8%2FOUBTjkoE6pYIk1c0bAOSEEGVbf3yo6Jy8aGzQHU181Gs7J3fvd2lqO3jH%2FkuWA3lSeZxq0Oy03fTWLJ8FWT6Ck72NAlknyAMYFmLtv%2BucF8KHMWA6BRuvyq8UmbP99j4NzRiVIRnIZzw8fgaS8o%2BYc%2FF6qnQM5R6fOBpe6qsrGEd7XkhYgHVrwixly03qciVPM3rtvdo6DMtTPMosgFDWwRL47ec3%2B2xOPKDX4wDty9Gj%2BH8ub8iWq87TlSaWgpdwEW23szGqavrLHkJqHmkAbHv8VyPqPMryMs3j%2BXMx8%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240505T220310Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAVRCD3NFRNSCWB5ZF%2F20240505%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=b5bb953d5c2a718070702d31ff8f64478e9e26129378d345b91e9bf8fd3f64ad"
          link.setAttribute('download', '');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.showMessageNotification({ type: 'success', description: 'You have successfully downloaded the latest version of agent app.' });
        }

      } catch( err ) {
        this.downloadAgentInProgress = false;
        this.showMessageNotification({ type: 'error', description: err });
      }

    },
    closeDefaultScheduleModal(){
      this.defaultScheduleModal = false;
      this.saveDefaultScheduleInProgress = false;
      this.selectedDefaultScheduleItem = {};
    },

    async validateArea(index) {
      const scopeName = `area.hb_area_${index}`;
      const result = await this.$validator.validate(scopeName);
      return result? true : false;
    },
    async validateAccessHour(index) {
      const scopeName = `area.access_hour_${index}`;
      const result = await this.$validator.validate(scopeName);
      return result ? true : false;
    },
    async validateAllAreas() {
      return Promise.all(this.accessAreas.map(async (_, index) => {
        const isAreaValid = await this.validateArea(index);
        const isHourValid = await this.validateAccessHour(index);
        return isAreaValid && isHourValid;
      }))
          .then(results => {
            return results.every(result => result);
          })
    },
    cancelAreaEdit(label){
      this.accessAreas = this.savedAccessAreas.slice();
      this.active = '';
    },

    cancelAccessLevel(label){
      this.displayErrors = {};
      this.accessLevels = this.tempAccessLevels.slice();
      this.active_levels = '';
    },

    editHasStarted(){
      this.savedAccessAreas = this.accessAreas.slice();
      if (this.accessAreas.length) {
        this.onboardUserAccessHourStatus = "guidance"
        this.onboardUserAccessHourDescription = "Onboard user access hours to enable tenant access priveleges."
        this.onboardUserAccessHourFlag = true;
        this.getOnboardingUserAccessHourStatus();
      }
    },

    editAccessLevels(){
      this.tempAccessLevels = this.accessLevels.slice();
    },

    async saveAccessAreas(label){
      //this.savedAccessAreas = this.accessAreas.slice()
      await this.$validator.validateAll('area').then(async isValid => {
        if (isValid) {
          this.changeAreaInProgress = true;
          this.active = '';
          for (let index = 0; index < this.accessAreas.length; index++) {

            const element = this.accessAreas[index];
            const foundArea = this.savedAccessAreas.find(item => item.name === element.name);

            const data = {
              id: element.id,
              property_id: this.property_id,
              name: element.name,
              default_vendor_access_level_id: element.default_vendor_access_level_id, //access hour
              vendor_access_area_id: element.vendor_access_area_id, // vendor access area / zone
              facility_id: this.accessFacilityId
            };

            if (this.sendExternalIdNumber) data.external_area_id_number = element.external_area_id_number;

            if(foundArea){

              await api.put(this, api.PROPERTIES + this.property_id + "/areas/" + element.id, data)
                  .then(results => {
                    this.showMessageNotification({ type: 'success', description: 'Access Areas updated successfully' });
                  })
                  .catch(error => {
                    this.showMessageNotification({ type: 'error', description: error });
                  })
            }else{
              await api.post(this, api.PROPERTIES + this.property_id + "/areas", data)
                  .then(results => {
                    this.showMessageNotification({ type: 'success', description: 'Access Areas created successfully' });
                  })
                  .catch(error => {
                    this.showMessageNotification({ type: 'error', description: error });
                  })
            }


          }

          for (let i = 0; i < this.savedAccessAreas.length; i++) {
            const element = this.savedAccessAreas[i];
            const deletedArea = this.accessAreas.find(area => area.id === element.id);

            if(!deletedArea){
              console.log("FOUND AREA TO DELETE: ", element)
              await api.delete(this, api.PROPERTIES + this.property_id + "/areas/" + element.id)
                  .then(results => {
                    this.showMessageNotification({ type: 'success', description: 'Access Areas deleted successfully' });
                  })
                  .catch(error => {
                    this.showMessageNotification({ type: 'error', description: error });
                  })
            }
          }

          await this.fetchAccessAreas();
          this.changeAreaInProgress = false;
        }
      }).catch((err) => {
        this.showMessageNotification({ type: 'error', description: err });
      })
    },

    async saveAccessLevels(label) {
      let validLevels = await this.$validator.validateAll('level');
      if (validLevels) {
        this.confirmLevelVisible = true;
      } else {
        this.displayErrors = this.errors.items.reduce((obj, item) => (obj[item.field] = true, obj), {});
        this.removeDuplicateErrors()
      }
    },
    async confirmAccessLevelChanges() {
      this.changeLevelInProgress = true;
      this.active_levels = '';
      let accessLevelChanges = this.accessLevels.map((level) => {
        return {
          id: level.id,
          name: level.name,
          external_id_number: parseInt(level.external_id_number)
        };
      });
      await api.put(this, api.PROPERTIES + this.property_id + "/facilities/" + this.accessFacilityId + "/access-levels", accessLevelChanges)
          .then(results => {
            this.showMessageNotification({ type: 'success', description: 'Access Hours updated successfully!' });
          })
          .catch(error => {
            this.showMessageNotification({ type: 'error', description: error });
          });
      await this.fetchAccessInfo();
      this.confirmLevelVisible = false;
      this.changeLevelInProgress = false;
    },

    removeDuplicateErrors() {
      let duplicateErrors = [];
      for (let item of this.errors.items) {
        let found = this.errors.items.find((element) => element.msg == item.msg);
        if (found && found.field != item.field) {
          duplicateErrors.push(item);
        }
      }
      while (duplicateErrors.length) {
        let error = duplicateErrors.pop();
        this.errors.remove("level." + error.field);
      }
    },
    close() {
      //this.$emit("closeDialog");
      this.dialogModal = false;
    },
    isCorrectFile() {
      var re = /(\.csv|\.xls|\.xlsx)$/i;
      if (this.files && this.files.name) {
        if (!re.exec(this.files.name)) {
          this.files = undefined;
          this.error = true;
        } else {
          this.error = false;
        }
      }
    },
    upload() {
      let formatedUserData;
      const file = this.files;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const sheetJson = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        formatedUserData = this.formatUserAccessData(sheetJson);

        this.onboardUserAccessHourInProgress = true;
        this.dialogModal = false;

        this.onboardUserAccessHourStatus = "guidance"
        this.onboardUserAccessHourDescription = "Access Hours onboarding in progress ...."
        let default_access_level_ID = this.accessAreas[0].default_vendor_access_level_id;
        await api.put(this, api.PROPERTIES + this.property_id + "/facilities/" + this.accessFacilityId + "/onboard-user-accesslevels", {default_access_level_id: default_access_level_ID, 'user_hours': formatedUserData}).then( r => {
          console.log("ONBOARDING RESPONSE: ", r)
        }).catch(error => {
          console.log("Error: ", error)
          this.onboardUserAccessHourStatus = "error"
          this.onboardUserAccessHourDescription = "An error has occured. Please onboard again."
          this.showMessageNotification({ type: 'error', description: err});

        });

        this.checkOnboardingAccessHoursStatus();

      };
      reader.readAsArrayBuffer(file);
    },
    formatUserAccessData(data) {

      let userData = [];

      switch (this.selectedAccessType) {
        
        case "pti": 
              
          data.map(person => {
            let user = {};
            let userUnits  = [];
            user["tenantName"] = person[0];
            if (person[2])
              user["gateCode"] = person[2].replace(/\s/g, "");
            if (person[3]){
              let unitNames = person[3].replace(/\s/g, "");
              userUnits = unitNames.split(",");
              user["units"] = userUnits;
            }
            if (person[4]){
              let timeZones = person[4].split(",");
              for (const index in timeZones) {
                timeZones[index] = timeZones[index].trim();
              }
              user["timeZones"] = timeZones;
            }

            if (user["tenantName"] != 'Full Name' && user["tenantName"] != 'All Users with Codes')
              userData.push(user);
          })
          return userData;
          
        case "rcs": 

          data.map(person => {
            let user = {};
            let userUnits  = [];
            if (person[2])
            user["tenantName"] = person[2].replace(/,/g, "").trim();
            if (person[5])
              user["gateCode"] = person[5].replace(/\s/g, "");
            if (person[0]){
              let unitNames = person[0].replace(/\s/g, "");
              userUnits = unitNames.split(",");
              user["units"] = userUnits;
            }
            if (person[9]){
              let timeZones = person[9].split(",");
              for (const index in timeZones) {
                timeZones[index] = timeZones[index].trim();
              }
              user["timeZones"] = timeZones;
            }
            if (person[10])
                user["keypadZone"] = person[10].trim();

            if (user["tenantName"] && user["tenantName"] != 'Name' && user["gateCode"] != 'Code') 
              userData.push(user);
          })
          return userData;     
      
      }
      
    },
    isDisable() {
      if (this.files) {
        return false;
      } else {
        return true;
      }
    },
    resetGateUpdateInfo(){
      this.current_version = null;
      this.latest_version = null;
      this.install_code = null;
      this.agent_type_id = null;
      this.gate_facility_id = null;
      this.isUpdateAvailable = false;
      this.hasNewVersion = false;
    },
    setAccessControlData(newData) {
      this.access = {}
      // this.selectedAccessType = null;
      // this.selectedGateAccessId = null;
      this.selectedIntegration = null;
      this.disableGateSelection = false;

      // if (newData.access.facility_id) {
      //   //set the access
      let newAccess = newData?.access
      if (newAccess?.Credentials) {
        this.access = Object.assign({}, newData.access);
        this.fetchAccessInfo()
      }
      //set the agent data
      if(newAccess?.agentInfo){
        this.current_version = newAccess?.agentInfo?.current_version;
        this.latest_version = newAccess ?.agentInfo?.latest_version;
        this.install_code = newAccess?.agentInfo?. install_code;
        this.agent_type_id = newAccess ?.agentInfo?.agent_type_id;
        this.gate_facility_id = newAccess?.agentInfo?.gate_facility_id;
        this.hasNewVersion = newAccess?.agentInfo?.hasNewVersion;
        if(!this.install_code && this.latest_version && this.hasNewVersion){
          api.post(this, api.ACCESS_CONTROL + "properties/" + this.property_id + "/agent/installcode?gate_facility_id=" + this.gate_facility_id + "&agent_type_id="+ this.agent_type_id).then(r => {
            this.install_code = r.install_code;
          });
        }
        if(this.latest_version && this.hasNewVersion){
          this.isUpdateAvailable = true;
        } else {
          this.isUpdateAvailable = false;
        }
      }
      // //set the access hours, area, and vendor areas
      // this.accessAreas = newData?.accessAreas ?? []
      // this.accessLevels = newData?.accessLevels ?? []
      // this.vendorAreas = newData?.vendorAreas ?? []

      // }
    }
  },
  watch: {
    accessControlData(newData) {
      this.setAccessControlData(newData);
    },
    access(value) {
      if (value && value.access_id) {
        if (this.integrationTypes?.length) {
          this.selectedIntegration = this.integrationTypes.find((integration) => integration.id === value.access_id)
        }
        if (value?.Credentials?.connected) this.disableGateSelection = true;
      } else {
        this.disableGateSelection = false;
      }
    },
    selectedAccessType() {
      if (this.selectedAccessType === 'pti' || this.selectedAccessType === 'default' || this.selectedAccessType === 'rcs' || this.selectedAccessType === 'doorking') {
          this.$emit('update:syncReportFlag', true);
        } else {
          this.$emit('update:syncReportFlag', false);
        }
    },
    confirmLevelVisible(val) {
      if (val === false) {
        this.newAreaSelection = {};
        this.confirmAreaMessage = '';
      }
    }
  },
  async created() {
    this.$validator.extend('unique_hb_area', {
      getMessage: field => 'Cannot use duplicate ' + field + ' names. Please create another name.',
      validate: (value, args) => {
        let accessAreas = args
        const counts = accessAreas.map(area => area.name);
        return counts.filter(item => item === value).length === 1;
      }
    });
    this.$validator.extend('unique_external_id', {
      getMessage: field => 'Duplicate ' + field + '. Please use another ID.',
      validate: (value, args) => {
        let accessLevels = args
        const idList = accessLevels.map(level => parseInt(level.external_id_number));
        let duplicateList = idList.filter(item => item === parseInt(value))
        let valid = duplicateList.length === 1;
        return valid;
      }
    });
    this.$validator.extend('unique_hour_name', {
      getMessage: field => 'Duplicate ' + field + '. Please use another name.',
      validate: (value, args) => {
        let accessLevels = args
        const counts = accessLevels.map(level => level.name);
        return counts.filter(item => item === value).length === 1;
      }
    });
    this.$validator.extend('unique_keypad_id', {
      getMessage: field => 'Duplicate ' + field + '. Please use another ID.',
      validate: (value, args) => {
        let accessAreas = args
        const idList = accessAreas.map(area => parseInt(area.external_area_id_number));
        let duplicateList = idList.filter(item => item === parseInt(value))
        let valid = duplicateList.length === 1;
        return valid;
      }
    });
    this.setAccessControlData(this.accessControlData)
    //await this.fetchData()
  },
};
</script>

<style scoped>

</style>
